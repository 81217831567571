import type { HTMLAttributes, ReactNode } from 'react';
import React from 'react';

import type { FormInputProps } from '../../utils/form';
import { cn } from '../../utils/styles';

import InputWrapper from './input-wrapper';

export type TextInputProps = {
  placeholder?: string;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  type?: 'text' | 'password' | 'text-area';
  idPrefix?: string;
  autoComplete?: string;
  label?: string;
  icon?: ReactNode;
} & FormInputProps<string>;

const TextInput = ({
  onChange,
  name,
  inputMode = 'text',
  type = 'text',
  autoComplete = 'on',
  error,
  idPrefix,
  value,
  placeholder,
  disabled,
  label,
  icon,
  ...rest
}: TextInputProps) => {
  return (
    <InputWrapper error={error} label={label}>
      <div className="relative">
        <input
          {...rest}
          id={name}
          autoComplete={autoComplete}
          value={value || ''}
          onChange={(e) => onChange?.(e.target.value || '')}
          type={type}
          inputMode={inputMode}
          disabled={disabled}
          className={cn(
            'w-full rounded-lg border border-stroke bg-transparent py-2 pl-2 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary',
            !!error && 'border-red-500',
            disabled && 'bg-gray',
          )}
          placeholder={placeholder}
        />
        {icon && <span className="absolute right-2 top-2">{icon}</span>}
      </div>
    </InputWrapper>
  );
};

export default TextInput;
