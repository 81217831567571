import { format, isBefore, parse } from 'date-fns';

import type { GroupedItem } from '../components/table/table';
import { DATE_FORMATS } from '../config/constants';
import type { GameInList } from '../services/game';

export const groupGamesByDay = (data: GameInList[]) => {
  const result: GroupedItem<GameInList>[] = [];

  const grouped = data.reduce((acc: Record<string, GameInList[]>, event) => {
    // Extract the date part from the event_date field
    const date = format(parse(event.event_date, DATE_FORMATS.DEFAULT_WITH_TIME, new Date()), DATE_FORMATS.DEFAULT);

    // If the date is not yet a key in the accumulator, create an array for it
    if (!(date in acc)) {
      acc[date] = [];
    }

    // Push the current event into the array for the corresponding date
    acc[date].push(event);

    return acc;
  }, {});

  Object.keys(grouped)
    .sort((a, b) => {
      return isBefore(parse(a, DATE_FORMATS.DEFAULT, new Date()), parse(b, DATE_FORMATS.DEFAULT, new Date())) ? -1 : 1;
    })
    .forEach((dateKey) => {
      result.push({
        type: 'SEPARATOR',
        data: {
          title: format(parse(dateKey, DATE_FORMATS.DEFAULT, new Date()), DATE_FORMATS.DATE_WITH_DAY_TEXT),
        },
      });
      grouped[dateKey].forEach((item) => {
        result.push({ type: 'ITEM', data: item });
      });
    });

  return result;
};
export const getGameRowStyle = (game: GameInList) => {
  let style = '';
  if (game.modified) {
    style = `${style} bg-modified`;
  }
  if (game.canceled) {
    style = `${style} bg-canceled`;
  }
  return style;
};
