import { Link } from '@tanstack/react-router';
import type React from 'react';
import { useState } from 'react';

import { useAuth } from '../../context/auth';
import { Route } from '../../routes/_public.login';
import { useCurrentUserQuery } from '../../services/user';
import { useTranslation } from '../../translations/useTranslation';
import ClickOutside from '../click-outside';

const DropdownUser = () => {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userQuery = useCurrentUserQuery();
  const { logout } = useAuth();
  const navigate = Route.useNavigate();

  if (userQuery.isLoading || !userQuery.data) {
    return null;
  }

  const itemClassName =
    'flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base';

  return (
    <ClickOutside onClick={() => setDropdownOpen(false)} className="relative">
      <button onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center gap-4">
        <span className="block text-right">
          <span className="block text-nowrap text-sm font-medium text-white">{userQuery.data.short_name}</span>
          <span className="block text-xs">{t(`msg_user_type_${userQuery.data.user_type}`)} </span>
        </span>

        <svg
          className="block fill-current"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
            fill=""
          />
        </svg>
      </button>

      {/* <!-- Dropdown Start --> */}
      {dropdownOpen && (
        <div className="absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark">
            {userQuery.data.is_admin && (
              <li>
                <Link to="/admin/mail-for-users" className={itemClassName}>
                  {t('msg_user_dropdown_email_to_users')}
                </Link>
              </li>
            )}
            <li>
              <Link to="/user-profile" className={itemClassName}>
                {t('msg_user_dropdown_edit_user_profile')}
              </Link>
            </li>
            <li>
              <Link to="/change-password" className={itemClassName}>
                {t('msg_user_dropdown_change_password')}
              </Link>
            </li>
          </ul>
          <button
            onClick={async () => {
              await logout();
              await navigate({ to: '/login' });
            }}
            className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          >
            {t('msg_user_dropdown_logout')}
          </button>
        </div>
      )}
      {/* <!-- Dropdown End --> */}
    </ClickOutside>
  );
};

export default DropdownUser;
