import type { ToastOptions } from 'react-toastify';
import { Bounce, toast } from 'react-toastify';

const toastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  theme: 'dark',
  transition: Bounce,
  style: {
    zIndex: 99999,
  },
};

export const toastError = (message: string) => {
  toast.error(message, toastOptions);
};
export const toastSuccess = (message: string) => {
  toast.success(message, toastOptions);
};
