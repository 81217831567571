import type { ReactNode } from 'react';
import type React from 'react';

import { cn } from '../../utils/styles';

import type { Column } from './table';

type RowProps<TableItem> = {
  columns: Column<TableItem>[];
  content: (column: Column<TableItem>) => React.ReactNode;
  rowStyle?: string;
  onRowClick?: () => void;
};

export const TableRow = <TableItem extends object>({ columns, content, rowStyle, onRowClick }: RowProps<TableItem>) => {
  const tableSize = columns.reduce((result, item) => {
    return result + item.size;
  }, 0);

  return (
    <RowContainer tableSize={tableSize} styling={rowStyle} onRowClick={onRowClick}>
      {columns.map((column, columnIndex) => {
        return (
          <div key={columnIndex} className={cn('col-span-1 flex gap-2 md:items-center', `md:col-span-${column.size}`)}>
            {column.Header && <div className="visible text-sm font-medium md:hidden">{column.Header}:</div>}

            <div className={cn('w-full text-sm text-black dark:text-white', column.truncateText && 'truncate')}>
              {content(column)}
            </div>
          </div>
        );
      })}
    </RowContainer>
  );
};

const RowContainer = ({
  children,
  tableSize,
  styling,
  onRowClick,
}: {
  children: ReactNode;
  tableSize: number;
  styling?: string;
  onRowClick?: () => void;
}) => {
  const colsClass = `md:grid-cols-${tableSize}`;
  return (
    <div
      className={cn(
        'grid grid-cols-1 gap-2 border-t border-stroke p-1 hover:bg-tableHover dark:border-strokedark md:grid-cols-11',
        colsClass,
        styling,
        onRowClick && 'cursor-pointer',
      )}
      onClick={onRowClick}
    >
      {children}
    </div>
  );
};

export const SeparatorRow = ({ tableSize, title }: { tableSize: number; title: string }) => (
  <RowContainer tableSize={tableSize}>
    <p className="col-span-full text-sm font-bold text-black dark:text-white">{title}</p>
  </RowContainer>
);

export const EmptyTableRow = ({ tableSize }: { tableSize: number }) => (
  <RowContainer tableSize={tableSize}>
    <div></div>
  </RowContainer>
);
