import { isAxiosError } from 'axios';

import type { Translator } from '../translations/translation-types';

import type { ErrorResponse } from './form';
import { toastError } from './toast';

export const noop = () => {};

export const handleMutationError = (err: unknown, t: Translator) => {
  if (isAxiosError<ErrorResponse>(err)) {
    const response = err.response;
    const message = response?.data.nonFieldErrors ? response.data.nonFieldErrors[0] : undefined;
    toastError(message || t('msg_toast_error_occurred'));
  } else {
    toastError(t('msg_toast_error_occurred'));
  }
};
