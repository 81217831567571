import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EnTranslations from './en';
import LtTranslations from './lt';
import type { Translator } from './translation-types';

import { logError } from '@/utils/logger';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  saveMissing: true,
  resources: {
    en: {
      translation: EnTranslations,
    },
    lt: {
      translation: LtTranslations,
    },
  },
  missingKeyHandler: (_lngs, _ns, key) => {
    logError(`Missing translation key: ${key}`);
  },
  interpolation: {
    escapeValue: false,
  },
  missingInterpolationHandler: (key) => {
    logError(`Missing translation param: ${key}`);
  },
});

export const translate = i18n.t.bind(i18n) as Translator;

export default i18n;
