import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import React from 'react';

import { ModalProvider } from '../components/modals/modal';
import EnvConfig from '../config/env';
import type { AuthContext } from '../context/auth';

type MyRouterContext = {
  auth: AuthContext;
};

const TanStackRouterDevtools = EnvConfig.IS_PRODUCTION
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      })),
    );

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: () => (
    <>
      <ModalProvider>
        <Outlet />
        <TanStackRouterDevtools />
      </ModalProvider>
    </>
  ),
});
