import React from 'react';

import type { GameAppointment } from '../../services/appointments';
import { useUpdateAppointmentStatusMutation } from '../../services/appointments';
import { useTranslation } from '../../translations/useTranslation';
import { toastSuccess } from '../../utils/toast';
import AppointmentStatusSelectInput from '../inputs/appointment-status-select-input';

//TODO commissioner_analytics
const GameAppointmentDisplay = ({ appointment }: { appointment: GameAppointment }) => {
  const updateAppointmentStatusMutation = useUpdateAppointmentStatusMutation();
  const { t } = useTranslation();
  if (!appointment.user) {
    return null;
  }
  return (
    <div className="flex flex-row items-center gap-1">
      <span className="text-nowrap">{appointment.user.short_name}</span>
      <AppointmentStatusSelectInput
        appointmentUserId={appointment.user.id}
        onChange={async (value) => {
          await updateAppointmentStatusMutation.mutateAsync({
            id: appointment.id,
            status: value,
          });
          toastSuccess(t('msg_toast_updated_successful'));
        }}
        value={appointment.status}
        name={`${appointment.id}_appointment_status`}
      />
    </div>
  );
};

export default GameAppointmentDisplay;
