import { createFileRoute, Outlet } from '@tanstack/react-router';
import React from 'react';

export const Route = createFileRoute('/_public')({
  beforeLoad: () => {
    localStorage.clear();
  },
  component: () => (
    <div className="container mx-auto w-125">
      <div className="mt-10 flex items-center justify-center md:mt-0 md:min-h-screen">
        <Outlet />
      </div>
    </div>
  ),
});
