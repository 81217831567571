export default {
  msg_site_title: 'Basketball CRM',

  msg_login_form_title: 'Login',

  msg_value_yes: 'Yes',
  msg_value_no: 'No',

  msg_no_items_found: 'No items found',

  //calendar
  msg_time: 'Time',

  //confirm
  msg_confirm_delete: 'Do you really want to remove?',

  //Archived
  msg_label_archived: 'Archived',
  msg_label_non_archived: 'Non archived',
  msg_label_without_league_group: 'Without league group',

  //Validations
  msg_error_field_invalid_email: 'Invalid email',
  msg_error_field_required: 'Field is required',
  msg_error_field_invalid_number: 'Invalid number',

  //Login
  msg_login_submit_button: 'Login',
  msg_forgot_password_text: 'Forgot password?',
  msg_forgot_password_link: 'Remind me',

  //Forgot password
  msg_forgot_password_form_title: 'Forgot password',
  msg_forgot_password_submit_button: 'Remind password',
  msg_login_link: 'Login',
  msg_forgot_password_success: 'Instructions sent to your email',

  //Errors
  msg_error_unexpected: 'Unexpected error',
  msg_error_invalid_credentials: 'Invalid credentials',
  msg_error_invalid_date_range: 'Invalid date range',
  msg_btn_reload: 'Reload page',

  //Table
  msg_table_header_league: 'League',
  msg_table_header_date: 'Date',
  msg_table_header_time: 'Time',
  msg_table_header_teams: 'Teams',
  msg_table_header_address: 'Address',
  msg_table_header_referees: 'Referees',
  msg_table_header_secretary: 'Secretary',
  msg_table_header_other_participants: 'Other participants',
  msg_table_header_game_number: 'No.',
  msg_table_header_user_type_in_event: 'User type',
  msg_table_header_ranking: 'Ranking',
  msg_table_header_game_count: 'Game count',
  msn_table_no_records: 'No Records',

  //Posts
  msg_posts_header: 'News',
  msg_field_content: 'Content',
  msg_post_field_send_mail: 'Send email?',
  msg_post_field_important: 'Important?',
  msg_label_new_post: 'New post',
  msg_label_edit_post: 'Edit post',

  //My games
  msg_my_games_header: 'My games',

  //Users
  msg_users_header: 'Users',

  //Full schedule
  msg_full_schedule_header: 'Full schedule',
  msg_button_export_from_selected_month_schedule: 'Export schedule from {{year}}-{{month}}',
  msg_button_export_selected_month_schedule: 'Export schedule for {{year}}-{{month}}',

  //leagues
  msg_leagues_header: 'Leagues',
  msg_league_field_code: 'Code',
  msg_league_field_league_group: 'League group',

  msg_league_form_data_fields: 'League data',
  msg_league_form_settings_fields: 'Settings',
  msg_league_field_in_cash: 'In cash?',
  msg_league_field_is_small: 'Is small?',
  msg_league_field_payed_by_other: 'Payed by other?',
  msg_league_field_invoiceable: 'Invoice needed?',
  msg_league_field_split_rankings: 'Split rankings',
  msg_league_form_ranking_fields: 'Ranking',

  //League groups
  msg_league_group_field_is_archived: 'Is archived?',
  msg_league_group_field_leagues: 'Leagues',
  msg_league_groups_header: 'League groups',
  msg_label_league_group_info: 'League group info',
  msg_label_edit_league_group: 'Edit league group',
  msg_label_new_league_group: 'New league group',
  msg_league_group_form_settings_fields: 'Settings',
  msg_league_group_field_balance_calculation: 'Balance calculation',

  //Game trip ranking
  msg_game_trip_rankings_groups_header: 'Game trip rankings',
  msg_label_new_game_trip_ranking: 'New Game trip ranking',
  msg_label_edit_game_trip_ranking: 'Edit Game trip ranking',
  msg_game_trip_ranking_form_data_fields: 'Game trip ranking data',
  msg_game_trip_ranking_form_ranking_fields: 'Rankings',

  //League filters
  msg_label_leagues_normal: 'Simple league',
  msg_label_leagues_small: 'Small league',
  msg_label_leagues_all: 'All leagues',
  msg_filter_league_type: 'League type',

  //Availability
  msg_my_availability_header: 'My availability',
  msg_availability_start_date: 'Start date',
  msg_availability_end_date: 'End date',
  msg_availability_comment: 'Comment',
  msg_availability_is_unavailable: 'Is unavailable?',
  msg_availability_modal_edit_title: 'Edit availability',
  msg_availability_modal_create_title: 'Create availability',
  msg_availability_form_repeat_section: 'Repeats on',
  msg_use_availability_header: 'Users availability',
  msg_user_availabilities_modal_title: 'Availability {{date}}',
  msg_user_availabilities_unavailable_title: 'Unavailable',
  msg_user_availabilities_available_title: 'Available',
  msg_game_nomination_header: 'Game nominations',

  //Button
  msg_button_load_more: 'Load more',
  msg_button_remove: 'Remove',
  msg_button_save: 'Save',
  msg_button_add: 'Add new',
  msg_button_export: 'Export',
  msg_button_export_with_date: 'Export for {{year}}-{{month}}',
  msg_button_new_import: 'New import',

  //Sidebar
  msg_sidebar_new_item: 'New',
  msg_sidebar_items_list: 'List',
  msg_sidebar_home_page: 'Home',
  msg_sidebar_users_page: 'Users',
  msg_sidebar_leagues_page: 'Leagues',
  msg_sidebar_league_groups_page: 'League groups',
  msg_sidebar_game_trip_rankings_page: 'Game trip rankings',
  msg_sidebar_schedule_page: 'Schedule',
  msg_sidebar_my_schedule: 'My schedule',
  msg_sidebar_full_schedule: 'Full schedule',
  msg_sidebar_my_availability: 'My availability',
  msg_sidebar_users_availability: 'Users availability',
  msg_sidebar_game_nominations: 'Game nominations',
  msg_sidebar_unconfirmed_games: 'Unconfirmed games',
  msg_sidebar_schedule_export: 'Schedule export',
  msg_sidebar_invoices: 'Invoices',

  msg_sidebar_availability_page: 'Availability',
  msg_sidebar_documents_page: 'Documents',
  msg_sidebar_journal_page: 'Journal',
  msg_sidebar_journal_my: 'My journal',
  msg_sidebar_journal_ranking: 'Ranking',
  msg_sidebar_journal_my_ranking: 'My Ranking',
  msg_sidebar_journal_my_expenses: 'My Expenses',
  msg_sidebar_journal_users_ranking: 'Users ranking',
  msg_sidebar_journal_export: 'Export',

  //Labels
  msg_label_game_info: 'Game info',
  msg_label_new_game: 'New game',
  msg_label_edit_game: 'Edit game',
  msg_label_game_nominations: 'Game nominations',
  msg_label_game_evaluations: 'Game evaluation',
  msg_label_game_expenses: 'Game expenses',
  msg_label_game_driver: 'Driver',
  msg_label_edit_user: 'Edit user',
  msg_label_new_user: 'New user',
  msg_label_user_info: 'User info',
  msg_label_league_info: 'League info',
  msg_label_edit_league: 'Edit league',
  msg_label_new_league: 'New league',
  //Fields
  msg_field_password: 'Password',
  msg_field_email: 'Email',
  msg_field_league: 'League',
  msg_field_date: 'Date',
  msg_field_name: 'Name',
  msg_field_file: 'File',
  msg_field_month_select: 'Month',

  msg_field_referee_ranking: 'Referee ranking',
  msg_field_secretary_score_ranking: 'Secretary score ranking',
  msg_field_secretary_statistics_ranking: 'Secretary statistics ranking',
  msg_field_secretary_time_ranking: 'Secretary time ranking',
  msg_field_commissioner_ranking: 'Commissioner ranking',
  msg_field_delegate_ranking: 'Delegate ranking',
  msg_field_secretary_4_ranking: 'Secretary 4 ranking',
  msg_field_secretary_5_ranking: 'Secretary 5 ranking',
  msg_field_secretary_statistics_2_ranking: 'Secretary statistics 2 ranking',
  msg_field_secretary_statistics_3_ranking: 'Secretary statistics 3 ranking',
  msg_field_secretary_information_ranking: 'Secretary information ranking',
  msg_field_commissioner_remotely_ranking: 'Commissioner remotely ranking',
  msg_field_referee_appointment_ranking: 'Referee appointment ranking',
  msg_field_secretary_appointment_ranking: 'Secretary appointment ranking',
  msg_field_trip_ranking: 'Trip ranking',
  msg_field_driver_ranking: 'Driver ranking',
  msg_field_referee_one_ranking: 'Crew chief ranking',
  msg_field_referee_two_ranking: 'Umpire 1 ranking',
  msg_field_referee_three_ranking: 'Umpire 2 ranking',

  //Game
  msg_game_field_game_number: 'Game number',
  msg_game_field_team1: 'Home team',
  msg_game_field_team2: 'Away team',
  msg_game_field_tv_broadcast: 'TV broadcast',
  msg_game_field_teams: 'Teams',
  msg_game_field_address: 'Address',
  msg_game_field_modified: 'Modified?',
  msg_game_field_canceled: 'Canceled?',
  msg_game_field_modified_text: 'What information changed',
  msg_game_field_commissioner_remotely: 'Commissioner remotely?',
  msg_game_field_is_trip: 'Kelionė?',

  //User type in event
  msg_user_type_referee_one: 'Crew chief',
  msg_user_type_referee_two: 'Umpire 1',
  msg_user_type_referee_three: 'Umpire 2',

  msg_user_type_secretary_score: 'Secretary score',
  msg_user_type_secretary_time: 'Scoreboard',
  msg_user_type_secretary_4: 'Game clock',
  msg_user_type_secretary_5: 'Shot clock',
  msg_user_type_secretary_statistics: 'Statictics - PC',
  msg_user_type_secretary_statistics_2: 'Statictics 1',
  msg_user_type_secretary_statistics_3: 'Statictics 2',
  msg_user_type_secretary_information: 'Informant',

  msg_user_type_delegate: 'Delegate',
  msg_user_type_commissioner: 'Commissioner',
  msg_user_type_referee: 'Referee',
  msg_user_type_administration: 'Administration',
  msg_user_type_secretary: 'Secretary',

  //Header
  msg_sidebar_button_add_game: 'Add game',
  msg_sidebar_button_game_upload: 'Game upload',
  msg_sidebar_button_add_post: 'Add post',
  msg_unconfirmed_games_header: 'Unconfirmed games',
  msg_full_schedule_export_header: 'Schedule export',
  msg_game_nomination_log_title: 'Game nomination history',
  msg_game_logs_title: 'Game history',

  //Toasts
  msg_toast_saved_successful: 'Saved successfully',
  msg_toast_updated_successful: 'Updated successfully',
  msg_toast_deleted_successful: 'Deleted successfully',
  msg_toast_error_occurred: 'Error occurred',
  //Appointment
  msg_appointment_status_pending: 'Pending',
  msg_appointment_status_approved: 'Approved',
  msg_appointment_status_declined: 'Declined',

  //User
  msg_user_form_data_fields: 'User data',
  msg_user_form_contact_fields: 'User contacts',
  msg_user_form_settings_fields: 'Settings',
  msg_user_form_invoice_fields: 'Invoice data',
  msg_user_form_info_fields: 'Info',
  msg_user_first_last_name: 'First Name, Last name',
  msg_user_field_first_name: 'First Name',
  msg_user_field_last_name: 'Last Name',
  msg_user_field_phone: 'Phone',
  msg_user_field_address: 'Address',
  msg_user_field_email: 'Email',
  msg_user_field_category: 'Category',
  msg_user_field_is_association_member: 'Is association member?',
  msg_user_field_person_code: 'Personal code',
  msg_user_field_account_number: 'Account number',
  msg_user_field_daily_mail: 'Daily reminder about game',
  msg_user_field_invoice_serial: 'Invoice serial',
  msg_user_field_invoice_number: 'Invoice number',
  msg_user_field_invoice_seller_info: 'Invoice seller',
  msg_user_field_suspended_until: 'Suspended till',
  msg_user_field_certificate_until: 'National certificate till',
  msg_user_field_user_type: 'Type',

  msg_user_category_none: 'No category',
  msg_user_category_fiba: 'FIBA',
  msg_user_category_lkl: 'LKL',
  msg_user_category_nkl: 'NKL',
  msg_user_category_rkl: 'RKL',
  msg_user_category_national: 'National',

  //Modal
  msg_modal_close_button_aria_label: 'Close',

  msg_user_type_display_filter_all: 'All',
  msg_user_type_display_filter_refs: 'Referees',
  msg_user_type_display_filter_none: 'None',
  msg_user_type_display_filter_persons: 'Other participants',
  msg_user_type_display_filter_secretary: 'Secretary',
  msg_filter_user_display_type: 'Display',

  //Documents
  msg_documents_header: 'Documents',
  msg_edit_document_category: 'Edit document category',
  msg_create_document_category: 'New Document category',
  msg_create_document: 'Upload document',

  msg_form_dropzone_file_rejected: 'Invalid file. Error: {{reason}}',
  msg_form_dropzone_choose_file_button: 'Choose file',

  //Journal
  msg_user_journal_header: 'User journal',
  msg_journal_total_games: 'Total games',
  msg_journal_total_ranking: 'Total ranking',
  msg_journal_trip_ranking: 'Trip ranking',
  msg_user_rankings_header: 'Rankings',
  msg_users_rankings_header: 'Users rankings',

  msg_expenses_export: 'Expenses export',

  msg_label_game_import: 'Game import info',
  msg_label_game_import_form: 'Game import',

  msg_label_game_import_info_1: 'You can upload games written in TSV format.',
  msg_label_game_import_info_2:
    'It is really important to check field order. Also you should check that each column is separated by Tab',
  msg_label_game_import_info_3: 'TSV file format:',
  msg_label_game_import_info_4: 'League code  Game number Date (2012-05-06) Time (18:45) Team Team Adress',
  msg_label_game_import_info_5: 'League code  Date (2012-05-06)   Time (18:45)  Team Team Adress',
  msg_label_game_import_info_6: 'Its really important to check correct quotes are used',
  msg_label_game_import_info_7: 'Game upload flow',

  msg_label_game_import_info_8: 'You should create Excel file with games',
  msg_label_game_import_info_9: 'Visit <googleLink>Google documents</googleLink>',
  msg_label_game_import_info_11: 'Create a spread sheet (Create > Spreadsheet) ',

  msg_label_game_import_info_12: 'Copy all info from excel to google docs',
  msg_label_game_import_info_13: 'Select (File) > (Download as) > (Plain text)',
  msg_label_game_import_info_14: 'Download file should be uploaded to system',
  msg_import_successful_rows_header: 'Successfully imported',
  msg_import_failed_rows_header: 'Failed to import',
  msg_game_import_edit_league: 'Edit league',
  msg_game_import_edit_game: 'Edit game',
  msg_game_import_stats_total: 'Total lines: {{count}}',
  msg_game_import_stats_success: 'Success lines: {{count}}',
  msg_game_import_stats_error: 'Invalid lines: {{count}}',

  msg_label_created_at: 'Created at',
  msg_label_updated_at: 'Updated at',

  msg_button_edit_trip_ranking: 'Edit trip ranking',
  msg_assign_game_trip_ranking: 'Assign game ranking',
  msg_button_preview: 'Preview',
  msg_button_edit: 'Edit',
  msg_button_delete: 'Delete',

  msg_journal_export_header: 'Journal export',

  msg_journal_export_by_league: 'Journal by league',
  msg_journal_export_by_league_group: 'Jornal by league group',
  msg_journal_export_total: 'Journal',
  msg_journal_export_game_cost: 'Schedule export with rankings',
  msg_journal_export_league_game_expenses: 'League group expenses',
  msg_journal_export_balance: 'Balance export',

  msg_mail_for_users_header: 'Email for users',
  msg_field_subject: 'subject',
  msg_mail_for_users_success: 'Email for users sent successfully',
  msg_user_dropdown_email_to_users: 'Email for users',
  msg_user_dropdown_logout: 'Logout',
  msg_user_dropdown_edit_user_profile: 'Edit profile',
  msg_user_dropdown_change_password: 'Change password',
  msg_label_profile_edit: 'Edit profile',
  msg_label_change_password: 'Password change',
  msg_forgot_password_change_success: 'Password changed successfully',

  msg_report_team_referee: 'Team/referees report',
  msg_report_full_schedule: 'Full schedule',
  msg_report_confirm_cancel_report: 'Confirmation/Cancellation report',
  msg_report_league_people: 'League and people report',
  msg_report_team_expenses: 'Team expenses report',

  //Invoices
  msg_invoices_header: 'Invoices',
  msg_invoice_name: 'Invoice',
  msg_label_new_invoice: 'New invoice',
  msg_invoice_form_data_fields: 'Invoice data',
  msg_invoice_field_serial: 'Serial',
  msg_invoice_field_number: 'Number',
  msg_invoice_field_date: 'Invoice date',
  msg_invoice_field_seller: 'Seller',
  msg_invoice_field_buyer: 'Buyer',
  msg_invoice_form_item_fields: 'Invoice items',
  msg_confirm_invoice_modal_title: 'Confirm invoice',

  msg_invoice_generated_download: 'Download generated invoice <download>here</download>',

  msg_invoice_data_correct_confirm_text: 'If invoice is correct, please confirm',
  msg_button_confirm_invoice: 'Confirm invoice',
  msg_button_edit_invoice: 'Edit invoice',
  msg_field_driver: 'Driver',
  msg_field_amount: 'Amount',
  msg_field_user: 'User',
  msg_create_expense_title: 'Create Expense',

  msg_label_evaluation: 'Evaluation',
  msg_no_permissions_title: 'No permissions to view this page',

  gameEvaluationGoodBadThings: 'Good/bad things description',
  gameEvaluationGoodThings: 'Good thing on this game',
  gameEvaluationBadThings: 'Things to improve',
  gameEvaluationReview: 'Gave evaluation review',
  videoSituations: 'Situations',
  videoSituation: 'Video situation {{number}}',
  fieldTime: 'Time',
  fieldQuarter: 'Quarter',
  fieldPriority: 'Priority',
  fieldComment: 'Comment',
  fieldEvaluation: 'Correct/Invorrect/Debatable',
  fieldDecision: 'Decision (foul, violation, other)',
  fieldReferees: 'Referees',
  fieldEvaluationHasVideo: 'Has video?',
  '1_quarter': '1 quarter',
  '2_quarter': '2 quarter',
  '3_quarter': '3 quarter',
  '4_quarter': '4 quarter',
  '1_overtime': '1 overtime',
  '2_overtime': '2 overtime',
  '3_overtime': '3 overtime',
  priority_center: 'Center',
  priority_lead: 'Lead',
  priority_trail: 'Trail',
  priority_double: 'Double priority',
  video_evaluation_correct: 'Correct call',
  video_evaluation_incorrect: 'Incorrect call',
  video_evaluation_debatable: 'Debatable',
  video_evaluation_correct_no_call: 'Correct no call',
  video_evaluation_incorrect_no_call: 'Incorrect no call',

  decision_out_of_bounds_v2: 'Out of bounds, backcourt violation',
  decision_time_control_v2: 'Shot clock, game clock',
  decision_blocking_v2: 'Block/Charge',
  decision_foul_player_with_ball_v2: 'Fouls agains player with ball (pushing, holding) ',
  decision_foul_without_ball_v2: 'Fouls without ball (hold, blocking) ',
  marginal_calls_v2: 'Marginal calls',
  decision_unsportsmanlike_foul_v2: 'UF,DF',

  decision_discipline_v2: 'Discipline (AOR, help, double call)',
  decision_mechanics_other_v2: 'Other (IOT, ...)',
  decision_game_control2_v2: 'OW,TF, bench control, communication',
  decision_looks_v2: 'Physical fitness and appearance (weight, speed, stamina, clothing, behavior)',
  decision_out_of_bounds: 'Out of bounds',
  decision_dribbling: 'Dribbling',
  decision_traveling: 'Traveling',
  decision_3sek: '3 sek.',
  decision_5sek: '5 sek.',
  decision_8sek: '8 sek.',
  decision_backcourt_violation: 'Backcourt violation',
  decision_foot_violation: 'Foot violation',
  decision_goaltending_interference: 'Goaltending/interference',
  decision_hand_ball: 'Hand ball',
  decision_ball_control: 'Ball controll',
  decision_holding: 'Holding',
  decision_blocking: 'Blocking, illegal screen',
  decision_block_change: 'Block/Charge',
  decision_pushing: 'Pushing',
  decision_handchecking: 'Handchecking',
  decision_illegal_use_of_hands: 'Illegal use of hands',
  decision_illegal_contact_to_hand: 'Illegal contact to hand',
  // hooking: 'Hooking',

  decision_swinging_the_elbow: 'Excessive swinging the elbow',
  decision_hit_to_head: 'Hit to the head',
  decision_aos: 'Act of shooting (AOS)',
  decision_flop: 'Flop',
  decision_technical_foul: 'Technical foul',
  decision_verticality: 'Verticality',
  decision_lpp: 'Low post play (LPP)',
  decision_protect_the_shooter: 'Protect the shooter',

  decision_participant_controll: 'Participant control',
  decision_administration: 'Administration (substititions, communication with secretary)',
  decision_time_control: 'Game clock control',
  decision_shot_clock_control: 'Shot clock control',
  decision_team_work: 'Team work, L, C, T',
  decision_double_call: 'Double call, AOR',
  decision_rotation: 'Rotation',
  decision_missing_help: 'Missing help',
  decision_individual_position: 'Individual position',

  decision_unsportsmanlike_foul: 'Unsportsmanlike foul',
  decision_hooking: 'Hooking',
  decision_no_call: 'No call',

  exportShortEvaluation: 'Export short evaluation',
  exportRefereeEvaluation: 'Export referee evaluation',
  exportEvaluation: 'Export evaluation',

  refereeEvaluationParameterFouls: 'Fouls',
  refereeEvaluationParameterGameControl: 'Game control',
  refereeEvaluationParameterViolations: 'Violations',
  refereeEvaluationParameterMechanics: 'Mechanics',
  refereeEvaluationParameterLooks: 'Looks',
  gameRefereeEvaluationByParams: 'Referee evaluation by params',
  refereeEvaluationParameterAgg: 'Agg. evaluation',
  gameEvaluationRefereeEvaluation: 'Referee evaluation',
  ranking_very_good: 'Very good',
  ranking_good: 'Good',
  ranking_satisfied: 'Satisfied',
  ranking_unsatisfied: 'Unsatisfied',

  msg_sidebar_videos_page: 'Video',
  msg_videos_header: 'Video',
  msg_field_description: 'Descripton',
  msg_sidebar_admin_page: 'Admin',
  msg_confirm_email_send: 'Do you really want to send email?',
  msg_toast_sent_successful: 'Sent successfully',
  msg_button_send_trip_info: 'Send trip info',
  msg_button_send: 'Send',
  msg_trip_info_modal_title: 'Trip info',

  msg_tooltip_edit: 'Edit',
  msg_tooltip_preview: 'Preview',
  msg_tooltip_history: 'History',
  msg_tooltip_delete: 'Delete',
  msg_tooltip_mail_for_game_users: 'Send email for participants',
  msg_tooltip_game_evaluation: 'Game evaluations',
  msg_tooltip_journal: 'User game journal',
  msg_tooltip_availability: 'User availability',
  msg_tooltip_add_to_calendar: 'Add to calendar',
  msg_trip_ranking: 'Trip ranking',

  msg_dashboard_global_statistics_games_count: 'Games',
  msg_dashboard_global_statistics_games_count_this_season: 'Games this season',
  msg_dashboard_global_statistics_refs_count: 'Referees',
  msg_dashboard_global_statistics_secretary_count: 'Secretary',
  msg_dashboard_global_statistics_commissioners_count: 'Commissioners',
  msg_dashboard_global_statistics_leagues_count: 'Leagues',
  msg_dashboard_my_games_count: 'Games this month',
  msg_dashboard_my_ranking: 'Month ranking',

  msg_suspended_until: 'Suspended till',
  msg_user_is_not_available_for_appointment: 'Busy',

  msg_label_balance_import: 'Balance import',
  msg_label_balance_import_info: 'Balance import info',
  msg_label_balance_import_form: 'Balance import',
  msg_journal_balance_ranking: 'Balance',
  msg_label_balances_header: 'Balances',
  msg_sidebar_button_balance: 'Balances',
  msg_sidebar_reports: 'Reports',

  msg_journal_prev_month_balance: 'Prev. month balance',
  msg_journal_cache_pay: 'Cache',
  msg_journal_transfer: 'Transfer',

  msg_label_balance_import_info_1:
    'Į sistemą galite įkelti vartotojus aprašytus CSV formatu. <bold> Labai svarbu, kad kiekvienas laukelis būtų savo vietoje. Taip pat, kad stulpelius skirtų ; (kabliataškis) </bold> ',
  msg_label_balance_import_info_2: 'CSV bylos formatas:  <bold>Vardas;Pavarde;Mėnesis;Metai;Reikšmė</bold>',

  //Tests
  msg_tests_header: 'Tests',
  msg_test_field_completed_until: 'Sprendimus pateikti iki',
  msg_tooltip_solve: 'Solve',
  msg_tooltip_answers: 'Atsakymai',
  msg_tooltip_results: 'Results',
  msg_tests_new_header: 'New test',
  msg_tests_edit_header: 'Edit test',
  msg_test_type_test: 'Test',
  msg_test_type_survey: 'Survey',
  msg_test_info_label: 'Test info',
  msg_test_questions_label: 'Questions',
  msg_test_field_question: 'Question',
  msg_test_field_question_type: 'Question type',
  msg_test_question_field_video: 'Video link',
  msg_test_question_type_simple: 'Simple',
  msg_test_question_type_video: 'Video',
  msg_test_field_question_answer_explanation: 'Explanation',
  msg_test_question_answer: 'Answer',
  msg_test_question_answer_correct: 'Correct',
  msg_test_field_enabled: 'Enabled',
  msg_button_add_answer: 'Add answer',
  msg_button_add_question: 'Add question',
  msg_sidebar_tests_page: 'Tests',

  msg_league_group_field_balance_calculation_amount: 'Balance calculation amount',
  msg_league_group_field_balance_calculation_amount_secretary: 'Balance calculation amount for secretary',

  msg_tests_answers_header: 'Test answers',
  msg_test_questions_answers_label: 'Answers',
  msg_tests_solve_header: 'Solve test',
  msg_user_export: 'Export',
  msg_user_export_all: 'Export all',

  msg_add_to_calendar_modal_title: 'Add to calendar',
} as const;
