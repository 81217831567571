import { Link } from '@tanstack/react-router';
import type { ReactNode } from 'react';
import React from 'react';

import { cn } from '../../utils/styles';
import LoadingSpinner from '../loading/loading-spinner';

type Variant = 'primary' | 'secondary' | 'danger';

export type Width = 'full' | 'fit-content';

type Size = 'base' | 'sm' | 'xs';

type ButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  width?: Width;
  size?: Size;
  type?: 'submit' | 'button';
  variant?: Variant;
  ariaLabel?: string;
};

const getButtonClassname = ({
  width,
  size,
  variant,
  disabled,
}: Pick<ButtonProps, 'loading' | 'width' | 'size' | 'variant' | 'disabled'>) =>
  cn(
    'relative h-fit text-center outline-none transition-opacity focus:border-blue-500 enabled:hover:opacity-90 disabled:opacity-40',
    width &&
      (
        {
          full: 'w-full',
          'fit-content': 'w-fit whitespace-nowrap',
        } satisfies Record<Width, string>
      )[width],
    size &&
      (
        {
          base: 'p-2 px-6',
          sm: 'px-6 py-1.5 font-semibold',
          xs: 'px-4 py-1 text-xs',
        } satisfies Record<Size, string>
      )[size],
    variant &&
      (
        {
          primary: 'rounded-lg bg-primary hover:brightness-75 text-white',
          secondary: 'rounded-full border border-blue-950 bg-white',
          // tertiary: 'bg-transparent py-2 text-blue-500',
          // 'rectangle-black': 'rounded-md bg-black text-white',
          danger: 'rounded-lg bg-red-500 hover:bg-red-500/90 text-white',
        } satisfies Record<Variant, string>
      )[variant],
    disabled && 'pointer-events-none opacity-40',
  );

const Button = ({
  children,
  disabled,
  loading,
  onClick,
  width = 'fit-content',
  size = 'base',
  type = 'button',
  variant = 'primary',
  ariaLabel,
}: ButtonProps) => {
  const buttonClassname = getButtonClassname({ width, size, variant, disabled });

  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      aria-label={ariaLabel}
      className={buttonClassname}
    >
      {loading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <LoadingSpinner />
        </div>
      )}

      <span className={cn({ 'opacity-0': loading })}>{children}</span>
    </button>
  );
};

const StyledLink = ({
  id,
  href,
  children,
  width = 'fit-content',
  size = 'base',
  variant = 'primary',
  disabled,
  ariaLabel,
  openInNewTab,
}: Omit<ButtonProps, 'type' | 'onClick'> & { id?: string; href: string; openInNewTab?: boolean }) => {
  const buttonClassname = getButtonClassname({ width, size, variant, disabled });

  return (
    <Link
      id={id}
      to={href}
      aria-label={ariaLabel}
      className={buttonClassname}
      target={openInNewTab ? '_blank' : undefined}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
    >
      {children}
    </Link>
  );
};

Button.Link = StyledLink;

export default Button;
