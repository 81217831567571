import type { AxiosResponse } from 'axios';
import { isAxiosError } from 'axios';
import type React from 'react';
import type {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseFormReturn,
  Path,
} from 'react-hook-form';

import { translate } from '../translations/translations-config';

import { translateBeErrorMessage } from './errors';
import { isBoolean, isDefined, isFileOrBlob, isNumber, isPrimitivesArray, isString, isStringArray } from './types';

const flattenObject = (obj: AnyObject, base = ''): AnyObject => {
  return Object.keys(obj).reduce<AnyObject>((acc, key) => {
    const value = obj[key];

    if (typeof value === 'object' && isDefined(value) && !isPrimitivesArray(value) && !isFileOrBlob(value)) {
      return { ...acc, ...flattenObject({ ...(value as object) }, `${base}${key}.`) };
    }

    return { ...acc, [`${base}${key}`]: value };
  }, {});
};

type AnyObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

export type ErrorResponse = { nonFieldErrors?: string[] } & AnyObject;

type MappedErrors = {
  fieldErrors?: { [x: string]: string[] };
  nonFieldErrors?: string[];
};

export type FormInputProps<Value> = {
  onChange?: (value: Value) => void;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  value: Value;
  name: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder?: string;
};

export const mapToInputProps = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  field,
  fieldState,
}: {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
}) => {
  return {
    onChange: field.onChange,
    onBlur: field.onBlur,
    value: field.value,
    name: field.name,
    error: fieldState.error?.message,
  } satisfies FormInputProps<FieldPathValue<TFieldValues, TName>>;
};
export const submitHandler = <T extends AnyObject>(
  form: UseFormReturn<T>,
  submitFn: (values: T) => Promise<unknown> | undefined,
) => {
  return form.handleSubmit(async (values) => {
    try {
      await submitFn(values);
    } catch (err) {
      mapErrorResponseToForm(err, form, values);
    }
  });
};
export const handleFormErrors = <T extends AnyObject>(err: AxiosResponse<ErrorResponse>, values: T): MappedErrors => {
  const response = err;

  if (
    response.status === 404 ||
    response.status === 403 ||
    (response.status === 500 && !response.data.nonFieldErrors)
  ) {
    return {
      nonFieldErrors: [translate('msg_error_unexpected')],
    };
  }
  if (response.status === 500 && response.data.nonFieldErrors) {
    return {
      nonFieldErrors: response.data.nonFieldErrors,
    };
  }
  if (typeof response.data !== 'object') {
    return {
      nonFieldErrors: [response.data],
    };
  }
  const { nonFieldErrors: nonFieldErrorsRaw, ...responseWithoutNonFieldErrors } = response.data;
  const nonFieldErrors = isStringArray(nonFieldErrorsRaw) ? nonFieldErrorsRaw : [];
  const flatValues = flattenObject(values);
  const flatData = flattenObject(response.data);

  Object.keys(responseWithoutNonFieldErrors).forEach((objectKey) => {
    if (!(objectKey in flatValues)) {
      const value = flatData[objectKey];
      if (isStringArray(value)) {
        nonFieldErrors.push(...value);
      }
    }
  });

  return {
    nonFieldErrors: nonFieldErrors.map(translateBeErrorMessage),
    fieldErrors: Object.keys(flatData)
      .filter((objectKey) => objectKey in flatValues)
      .reduce((acc, field) => {
        const errors = flatData[field];
        if (isStringArray(errors)) {
          return { ...acc, [field]: errors.map(translateBeErrorMessage) };
        }
        return acc;
      }, {}),
  };
};

export const mapErrorResponseToForm = <T extends AnyObject>(err: unknown, form: UseFormReturn<T>, values: T) => {
  if (!isAxiosError<ErrorResponse>(err) || !err.response) {
    throw err;
  }

  const { fieldErrors, nonFieldErrors } = handleFormErrors<T>(err.response, values);

  if (nonFieldErrors?.length) {
    form.setError('root', {
      message: nonFieldErrors[0],
    });
  }

  if (fieldErrors) {
    Object.keys(fieldErrors).forEach((fieldName) => {
      form.setError(fieldName as Path<T>, { message: fieldErrors[fieldName][0] });
    });
  }
};
export const convertToFormData = (values: AnyObject) => {
  const formData = new FormData();

  Object.keys(values).forEach((key) => {
    const value = values[key];

    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (isFileOrBlob(item) || isString(item)) {
          formData.append(key, item);
        }

        if (isBoolean(item) || isNumber(item)) {
          formData.append(key, item.toString());
        }
      });
    }

    if (isFileOrBlob(value) || isString(value)) {
      formData.append(key, value);
    }

    if (isBoolean(value) || isNumber(value)) {
      formData.append(key, value.toString());
    }
  });

  return formData;
};
