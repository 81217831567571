import * as React from 'react';
import { useState } from 'react';

export type PageContext = {
  title: string;
  setTitle: (title: string) => void;
};

const PageContext = React.createContext<PageContext | null>(null);

export function PageContextProvider({ children }: { children: React.ReactNode }) {
  const [title, setTitle] = useState('');
  return <PageContext.Provider value={{ title, setTitle }}>{children}</PageContext.Provider>;
}

export function usePage() {
  const context = React.useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within an PageContextProvider');
  }
  return context;
}
