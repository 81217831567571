import { translate } from '../translations/translations-config';

import { logError } from './logger';
import { hasOwnProperty } from './types';

const errorToMessage = {
  error_unexpected: 'msg_error_unexpected',
  error_invalid_credentials: 'msg_error_invalid_credentials',
} as const;

export const translateBeErrorMessage = (key: string): string => {
  if (hasOwnProperty(errorToMessage, key)) {
    return translate(errorToMessage[key]);
  }

  logError(`Missing BE error translations: ${key}`);
  return key;
};
