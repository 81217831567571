import type { ReactNode } from 'react';
import React from 'react';

import CalendarIcon from '../assets/calendar.svg?react';
import DashboardIcon from '../assets/dashboard.svg?react';
import DocumentIcon from '../assets/document.svg?react';
import InvoicesIcon from '../assets/invoices.svg?react';
import JournalIcon from '../assets/journal.svg?react';
import LeagueIcon from '../assets/league.svg?react';
import TestIcon from '../assets/test.svg?react';
import UserIcon from '../assets/user.svg?react';
import VideoIcon from '../assets/video.svg?react';
import type { Permission } from '../hooks/useCheckPermission';
import type { MessageKey } from '../translations/translation-types';

type MenuItem = { title: MessageKey; link: string };

type SubItem = MenuItem & { permissions: Permission[] };

const SVG_ITEM_PROPS = {
  className: 'fill-current',
  width: 22,
  height: 22,
} as const;

export const MENU_ITEMS: (MenuItem & { icon: ReactNode } & (
    | { subItems: SubItem[]; permissions?: never }
    | { subItems?: undefined; permissions: Permission[] }
  ))[] = [
  {
    title: 'msg_sidebar_home_page',
    link: '/',
    icon: <DashboardIcon {...SVG_ITEM_PROPS} />,
    permissions: ['user'],
  },
  {
    title: 'msg_sidebar_admin_page',
    link: '/video',
    icon: <JournalIcon {...SVG_ITEM_PROPS} />,
    subItems: [
      { title: 'msg_sidebar_button_add_game', link: '/games/new', permissions: ['admin'] },
      {
        title: 'msg_sidebar_game_nominations',
        link: '/games/assign',
        permissions: ['admin'],
      },
      { title: 'msg_sidebar_button_game_upload', link: '/games/import', permissions: ['admin'] },
      { title: 'msg_sidebar_button_add_post', link: '/posts/new', permissions: ['admin'] },
      { title: 'msg_sidebar_button_balance', link: '/balance', permissions: ['admin', 'balance'] },
      { title: 'msg_sidebar_reports', link: '/admin/reports', permissions: ['admin'] },
    ],
  },

  {
    title: 'msg_sidebar_my_schedule',
    link: '/schedule/my',
    icon: <CalendarIcon {...SVG_ITEM_PROPS} />,
    permissions: ['user'],
  },
  {
    title: 'msg_sidebar_my_availability',
    link: '/availability',
    icon: <UserIcon {...SVG_ITEM_PROPS} />,
    permissions: ['user', 'availability'],
  },
  {
    title: 'msg_sidebar_schedule_page',
    link: '/schedule',
    icon: <CalendarIcon {...SVG_ITEM_PROPS} />,
    subItems: [
      { title: 'msg_sidebar_full_schedule', link: '/schedule/full', permissions: ['user', 'schedule:full'] },
      { title: 'msg_sidebar_button_add_game', link: '/games/new', permissions: ['admin'] },
      { title: 'msg_sidebar_button_game_upload', link: '/games/import', permissions: ['admin'] },
      { title: 'msg_sidebar_schedule_export', link: '/schedule/export', permissions: ['admin'] },
      {
        title: 'msg_sidebar_game_nominations',
        link: '/games/assign',
        permissions: ['admin'],
      },
      { title: 'msg_sidebar_unconfirmed_games', link: '/games/unconfirmed', permissions: ['admin'] },
    ],
  },

  {
    title: 'msg_sidebar_users_page',
    link: '/users',
    subItems: [
      { title: 'msg_sidebar_items_list', link: '/users', permissions: ['user'] },
      {
        title: 'msg_sidebar_users_availability',
        link: '/availability/users-availability',
        permissions: ['admin', 'availability'],
      },
      { title: 'msg_sidebar_journal_users_ranking', link: '/journal/users-ranking', permissions: ['admin'] },
    ],
    icon: <UserIcon {...SVG_ITEM_PROPS} />,
  },

  {
    title: 'msg_sidebar_leagues_page',
    link: '/leagues',
    icon: <LeagueIcon {...SVG_ITEM_PROPS} />,
    subItems: [
      { title: 'msg_sidebar_items_list', link: '/leagues', permissions: ['user'] },

      {
        title: 'msg_sidebar_league_groups_page',
        link: '/leagues/league-groups',
        permissions: ['admin', 'league-groups'],
      },
      {
        title: 'msg_sidebar_game_trip_rankings_page',
        link: '/leagues/trip-rankings',
        permissions: ['admin', 'trip-ranking'],
      },
    ],
  },

  {
    title: 'msg_sidebar_journal_page',
    link: '/journal/my',
    icon: <InvoicesIcon {...SVG_ITEM_PROPS} />,
    subItems: [
      { title: 'msg_sidebar_journal_my', link: '/journal/my', permissions: ['user'] },
      { title: 'msg_sidebar_journal_my_ranking', link: '/journal/ranking', permissions: ['user'] },
      { title: 'msg_sidebar_journal_my_expenses', link: '/journal/expenses', permissions: ['expenses:report'] },
      { title: 'msg_sidebar_journal_export', link: '/journal/export', permissions: ['admin'] },
    ],
  },
  {
    title: 'msg_sidebar_invoices',
    link: '/invoices',
    permissions: ['user', 'invoices'],
    icon: <InvoicesIcon {...SVG_ITEM_PROPS} />,
  },
  {
    title: 'msg_sidebar_documents_page',
    link: '/documents',
    permissions: ['documents'],
    icon: <DocumentIcon {...SVG_ITEM_PROPS} />,
  },
  {
    title: 'msg_sidebar_videos_page',
    link: '/video',
    icon: <VideoIcon {...SVG_ITEM_PROPS} />,
    permissions: ['video'],
  },
  {
    title: 'msg_sidebar_tests_page',
    link: '/tests',
    icon: <TestIcon {...SVG_ITEM_PROPS} />,
    permissions: ['tests'],
  },
];
