import { createFileRoute } from '@tanstack/react-router';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

import Box, { BoxContent, BoxHeader } from '../../components/box';
import Button from '../../components/button/button';
import pageWrapper from '../../components/page-wrapper';
import PostItem from '../../components/post/post-item';
import StatsBox from '../../components/stats-box';
import Table from '../../components/table/table';
import { DATE_FORMATS } from '../../config/constants';
import useGameListColumns from '../../hooks/useGameListColumns';
import useNavigation from '../../hooks/useNavigation';
import { useAppConfigQuery, useAppStatisticsQuery } from '../../services/app';
import { useMyGamesQuery } from '../../services/game';
import type { BaseGameQueryParams } from '../../services/game';
import { usePostsQuery } from '../../services/post';
import { useCurrentUserQuery, useUserMonthStatisticsQuery } from '../../services/user';
import { useTranslation } from '../../translations/useTranslation';
import { getGameRowStyle, groupGamesByDay } from '../../utils/game';

const Stats = () => {
  const { t } = useTranslation();
  const appConfigQuery = useAppConfigQuery();
  const appStatisticsQuery = useAppStatisticsQuery();
  const userMonthStatisticsQuery = useUserMonthStatisticsQuery();

  const renderStatsBox = (label: string, result: number) => {
    return <StatsBox label={label} result={result} />;
  };

  return (
    <div className="mb-2 hidden gap-2 sm:flex-row md:flex">
      {renderStatsBox(
        t('msg_dashboard_my_games_count'),
        userMonthStatisticsQuery.data?.reduce((sum, item) => {
          return sum + parseInt(item.appointments_count);
        }, 0) || 0,
      )}
      {renderStatsBox(
        t('msg_dashboard_my_ranking'),
        userMonthStatisticsQuery.data?.reduce((sum, item) => {
          return sum + parseFloat(item.summed_driver_ranking) + parseFloat(item.summed_ranking);
        }, 0) || 0,
      )}
      {renderStatsBox(
        t('msg_dashboard_global_statistics_games_count_this_season'),
        appStatisticsQuery.data?.seasonGamesCount || 0,
      )}
      {renderStatsBox(t('msg_dashboard_global_statistics_games_count'), appStatisticsQuery.data?.gamesCount || 0)}
      {renderStatsBox(t('msg_dashboard_global_statistics_refs_count'), appStatisticsQuery.data?.users.refsCount || 0)}
      {appConfigQuery.data?.features.appointments.secretary &&
        renderStatsBox(
          t('msg_dashboard_global_statistics_secretary_count'),
          appStatisticsQuery.data?.users.secretaryCount || 0,
        )}
      {appConfigQuery.data?.features.appointments.commissioner &&
        renderStatsBox(
          t('msg_dashboard_global_statistics_commissioners_count'),
          appStatisticsQuery.data?.users.commissionersCount || 0,
        )}
      {renderStatsBox(t('msg_dashboard_global_statistics_leagues_count'), appStatisticsQuery.data?.leaguesCount || 0)}
    </div>
  );
};

const MyGamesBox = () => {
  const { t } = useTranslation();
  const { navigateToGame } = useNavigation();

  const params: BaseGameQueryParams = {
    startDate: format(new Date(), DATE_FORMATS.DEFAULT),
    leagueType: 'all',
  };

  const myGamesQuery = useMyGamesQuery(params);
  const userQuery = useCurrentUserQuery();

  const games = useMemo(
    () => myGamesQuery.data?.pages.flatMap((page) => page.collection) || [],
    [myGamesQuery.data?.pages],
  );

  const columns = useGameListColumns({
    displayRefs: userQuery.data?.user_type === 'referee',
    displaySecretary: userQuery.data?.user_type === 'secretary',
    displayPersons: userQuery.data?.user_type === 'commissioner',
  });

  return (
    <Box>
      <BoxContent>
        <BoxHeader>{t('msg_my_games_header')}</BoxHeader>
        <Table
          columns={columns}
          data={games}
          loading={myGamesQuery.isLoading}
          rowGroupSeparator={{
            groupBy: groupGamesByDay,
          }}
          rowStyling={getGameRowStyle}
          hasNextPage={myGamesQuery.hasNextPage}
          loadMore={() => {
            myGamesQuery.fetchNextPage();
          }}
          onRowClick={async (item) => {
            await navigateToGame(item.id);
          }}
        />
      </BoxContent>
    </Box>
  );
};

const PostsBox = () => {
  const { t } = useTranslation();
  const postsQuery = usePostsQuery(5);

  const posts = useMemo(
    () => postsQuery.data?.pages.flatMap((page) => page.collection) || [],
    [postsQuery.data?.pages],
  );

  return (
    <Box>
      <BoxContent>
        <BoxHeader>{t('msg_posts_header')}</BoxHeader>
        {(() => {
          return (
            <>
              <div className="flex flex-col items-start gap-3">
                {posts.map((post) => {
                  return <PostItem key={post.id} post={post} />;
                })}
              </div>
              {postsQuery.hasNextPage && (
                <div className="mt-5 flex justify-center">
                  <Button onClick={postsQuery.fetchNextPage} loading={postsQuery.isLoading}>
                    {t('msg_button_load_more')}
                  </Button>
                </div>
              )}
            </>
          );
        })()}
      </BoxContent>
    </Box>
  );
};

const DashboardPage = () => {
  return (
    <>
      <Stats />

      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-12 md:col-span-8">
          <MyGamesBox />
        </div>
        <div className="col-span-12 md:col-span-4">
          <PostsBox />
        </div>
      </div>
    </>
  );
};

export const Route = createFileRoute('/_auth/')({
  component: pageWrapper(DashboardPage, undefined),
});
