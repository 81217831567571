import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { apiInstance } from '../utils/api';

import type { User } from './user';

export const queryKeys = {
  gameAppointments: (gameId: number) => ['gameAppointments', gameId],
};

export type UserTypeInEvent =
  | 'referee_one'
  | 'referee_two'
  | 'referee_three'
  | 'secretary_score'
  | 'secretary_time'
  | 'secretary_4'
  | 'secretary_5'
  | 'commissioner'
  | 'secretary_information'
  | 'secretary_statistics'
  | 'secretary_statistics_2'
  | 'secretary_statistics_3'
  | 'delegate';

export type GameAppointmentStatus = 'pending' | 'approved' | 'declined' | 'empty';
export type GameAppointment = {
  id: number;
  status: GameAppointmentStatus;
  user: User | null;
  user_id: number;
  game_id: number;
  user_type_in_event: UserTypeInEvent;
  game: { commissioner_remotely: boolean };
};

type AppointmentPayload = {
  game_id: number;
  status: GameAppointmentStatus;
  user_id: number;
  user_type_in_event: UserTypeInEvent;
};

export const fetchGameAppointments = async (gameId: number) => {
  const response = await apiInstance.get<GameAppointment[]>(`/api/service/game-appointments`, { params: { gameId } });
  return response.data;
};

export const useGameAppointmentsQuery = (gameId: number) => {
  return useQuery({
    staleTime: Infinity,
    queryKey: queryKeys.gameAppointments(gameId),
    queryFn: () => fetchGameAppointments(gameId),
  });
};
export const updateAppointment = async (data: AppointmentPayload & { id: number }) => {
  const res = await apiInstance.put<GameAppointment>(`/api/service/game-appointment/${data.id}`, data);
  return res.data;
};
export const useUpdateAppointmentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAppointment,
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.gameAppointments(result.game_id) });
    },
  });
};
export const updateAppointmentStatus = async (data: { id: number; status: GameAppointmentStatus }) => {
  const res = await apiInstance.put<GameAppointment>(`/api/service/game-appointment/${data.id}/status/${data.status}`);
  return res.data;
};
export const useUpdateAppointmentStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAppointmentStatus,
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.gameAppointments(result.game_id) });
    },
  });
};
