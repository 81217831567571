import type { Validate } from 'react-hook-form';
import { isEmail, isNumeric } from 'validator';

import type { Translator } from '../translations/translation-types';

import { isDefined } from './types';

export const emailRule = (t: Translator): Validate<string, unknown> => {
  return (value) => {
    return !value || isEmail(value) ? undefined : t('msg_error_field_invalid_email');
  };
};
export const requiredRule = (t: Translator): Validate<unknown, unknown> => {
  return (value) => {
    return isDefined(value) && value !== '' ? undefined : t('msg_error_field_required');
  };
};

export const validateRules = <T>(...validators: Array<Validate<T, unknown> | undefined>) => {
  return {
    validate: (value: T, formValues: unknown) => {
      const failedRule = validators.filter(isDefined).find((item) => item(value, formValues) !== undefined);
      return failedRule ? failedRule(value, formValues) : undefined;
    },
  };
};

export const numberRule = (t: Translator): Validate<string, unknown> => {
  return (value) => {
    return !value || isNumeric(value) ? undefined : t('msg_error_field_invalid_number');
  };
};
