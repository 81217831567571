import React from 'react';

import type { GameAppointment } from '../../services/appointments';
import { useGameAppointmentsQuery } from '../../services/appointments';
import { sortAppointments } from '../../utils/sort';

import GameAppointmentDisplay from './game-appointment-display';

const GameAppointments = ({
  gameId,
  filter,
}: {
  gameId: number;
  filter: (gameAppointment: GameAppointment) => boolean;
}) => {
  const gameAppointmentsQuery = useGameAppointmentsQuery(gameId);

  const appointments = (gameAppointmentsQuery.data || []).filter(filter).sort(sortAppointments);

  return (
    <div className="flex flex-wrap gap-1">
      {appointments.map((appointment) => {
        return <GameAppointmentDisplay appointment={appointment} key={appointment.id} />;
      })}
    </div>
  );
};

export default GameAppointments;
