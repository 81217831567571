import { Link, useRouter } from '@tanstack/react-router';
import React, { useEffect } from 'react';

import { LOGO_URL } from '../../config/constants';
import { MENU_ITEMS } from '../../config/sidebar-config';
import useCheckPermission from '../../hooks/useCheckPermission';
import { useTranslation } from '../../translations/useTranslation';
import { cn } from '../../utils/styles';

import SidebarLinkGroup from './sidebar-link-group';

type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  isLGScreen: boolean;
};

const Sidebar = ({ sidebarOpen, setSidebarOpen, isLGScreen }: SidebarProps) => {
  const { t } = useTranslation();
  const { pathname } = location;

  const { hasAll } = useCheckPermission();

  const router = useRouter();

  useEffect(() => {
    const unsubOnResolved = router.subscribe('onResolved', () => {
      if (!isLGScreen) {
        setSidebarOpen(false);
      }
    });

    return () => {
      unsubOnResolved();
    };
  }, [router, setSidebarOpen, isLGScreen]);

  return (
    <aside
      className={`absolute left-0 top-0 z-999 flex h-screen w-full flex-col overflow-y-hidden bg-white pt-4 duration-300 ease-linear dark:bg-boxdark md:w-60 ${
        sidebarOpen ? 'translate-x-0 lg:static' : '-translate-x-full'
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="mb-2 flex items-center justify-center gap-2">
        <Link to="/" className="px-2">
          <img src={LOGO_URL} alt="Logo" className="max-h-[150px]" />
        </Link>

        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="absolute right-2 top-2 block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-2 p-2">
          {/* <!-- Menu Group --> */}
          <div>
            <ul className="mb-6 flex flex-col gap-1.5">
              {MENU_ITEMS.filter((menuItem) => {
                if (menuItem.permissions) {
                  return hasAll(menuItem.permissions);
                }
                return menuItem.subItems
                  .map((item) => {
                    return hasAll(item.permissions);
                  })
                  .some((hasPermission) => {
                    return hasPermission === true;
                  });
              }).map((item, index) => {
                if (item.subItems) {
                  const isActiveLink =
                    pathname.includes(item.link) ||
                    item.subItems
                      .map((subItem) => pathname.includes(subItem.link))
                      .some((samePathName) => {
                        return samePathName;
                      });

                  return (
                    <SidebarLinkGroup key={index} activeCondition={isActiveLink}>
                      {(handleClick, open) => {
                        return (
                          <>
                            <button
                              className={cn(
                                'group relative flex w-full items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out hover:opacity-90',
                                open && 'bg-gray',
                                isActiveLink && 'bg-gray',
                              )}
                              onClick={(e) => {
                                e.preventDefault();
                                handleClick();
                              }}
                            >
                              {item.icon}
                              {t(item.title as any)}
                              <svg
                                className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${open && 'rotate-180'}`}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                                  fill=""
                                />
                              </svg>
                            </button>
                            <div className={cn(`translate overflow-hidden`, !open && 'hidden')}>
                              <ul className="flex flex-col gap-1 py-2 pl-5">
                                {item.subItems
                                  .filter((item) => {
                                    return hasAll(item.permissions);
                                  })
                                  .map((item, subItemIndex) => {
                                    return (
                                      <li key={subItemIndex}>
                                        <Link
                                          to={item.link}
                                          className="group relative flex items-center p-2 font-medium text-black duration-300 ease-in-out hover:text-primary"
                                          activeProps={{
                                            // className: 'text-primary',
                                            // className: 'bg-gray dark:bg-meta-4  rounded-lg',
                                            className:
                                              'bg-primary dark:bg-meta-4  rounded-lg text-white hover:bg-transparent',
                                          }}
                                        >
                                          {t(item.title as any)}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </>
                        );
                      }}
                    </SidebarLinkGroup>
                  );
                }
                return (
                  <li key={index}>
                    <Link
                      to={item.link}
                      activeProps={{
                        className: 'bg-primary text-white',
                      }}
                      className="group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out hover:opacity-90"
                    >
                      {item.icon}
                      {t(item.title as any)}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;
