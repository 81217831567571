import React from 'react';

import GCal from '../../assets/calendar/gcal.svg?react';
import ICal from '../../assets/calendar/ical.svg?react';
import Outlook from '../../assets/calendar/outlook.svg?react';

import CenteredModalWrapper from './centered-modal-wrapper';
import type { ModalProps } from './modal';

import { useTranslation } from '@/translations/useTranslation';

type AddToCalendarModalProps = ModalProps & {
  closeModal: (
    props?: { action: 'SELECTED'; provider: 'google' | 'default' | 'outlookcom' } | { action: 'CLOSE' },
  ) => void;
};

const AddToCalendarModal = ({ closeModal }: AddToCalendarModalProps) => {
  const { t } = useTranslation();

  return (
    <CenteredModalWrapper onClose={closeModal} title={t('msg_add_to_calendar_modal_title')} limitWidth>
      {(() => {
        return (
          <div className="flex flex-col gap-2">
            <button
              className="flex items-center gap-1"
              onClick={() => {
                closeModal({ action: 'SELECTED', provider: 'google' });
              }}
            >
              <GCal />
              Google Calendar
            </button>
            <button
              className="flex items-center gap-1"
              onClick={() => {
                closeModal({ action: 'SELECTED', provider: 'outlookcom' });
              }}
            >
              <Outlook />
              Outlook
            </button>
            <button
              className="flex items-center gap-1"
              onClick={() => {
                closeModal({ action: 'SELECTED', provider: 'default' });
              }}
            >
              <ICal />
              iCalc
            </button>
          </div>
        );
      })()}
    </CenteredModalWrapper>
  );
};

export default AddToCalendarModal;
