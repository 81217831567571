import React from 'react';

type FormErrorProps = {
  error: string;
};

const Error = ({ error }: FormErrorProps) => {
  return <p className="text-xs text-red-500">{error}</p>;
};

export default Error;
