import React from 'react';

import LoadingSpinner from './loading-spinner';

const FullScreenLoader = () => {
  return (
    <div className="flex min-h-screen min-w-full items-center justify-center">
      <LoadingSpinner height={80} />
    </div>
  );
};

export default FullScreenLoader;
