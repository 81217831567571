import { useSelect } from 'downshift';
import React from 'react';

import ConfirmedIcon from '../../assets/status/confirmed.svg?react';
import PendingIcon from '../../assets/status/pending.svg?react';
import RejectedIcon from '../../assets/status/rejected.svg?react';
import type { UseInputProps } from '../../hooks/useInput';
import { useInput } from '../../hooks/useInput';
import type { GameAppointmentStatus } from '../../services/appointments';
import type { User } from '../../services/user';
import { useCurrentUserQuery } from '../../services/user';
import { useTranslation } from '../../translations/useTranslation';
import type { FormInputProps } from '../../utils/form';
import { cn } from '../../utils/styles';

const menuItemCount = 5;
const menuItemHeight = 38;

export type SelectInputProps = {
  appointmentUserId: number | null;
} & FormInputProps<GameAppointmentStatus> &
  UseInputProps;
export const getAppointmentStatusIcon = (status: GameAppointmentStatus, disabled: boolean) => {
  const className = disabled ? 'opacity-30' : '';
  if (status === 'pending') {
    return <PendingIcon width={20} height={20} className={className} />;
  }
  if (status === 'approved') {
    return <ConfirmedIcon width={20} height={20} className={className} />;
  }
  if (status === 'declined') {
    return <RejectedIcon width={20} height={20} className={className} />;
  }
  return null;
};

//GameAppointmentConfirmButtonView
const canChangeStatus = (disabled: boolean, user: User, appointmentUserId: number) => {
  if (disabled) {
    return false;
  }
  if (user.is_admin) {
    return true;
  }
  return appointmentUserId === user.id;
};

const AppointmentStatusSelectInput = (props: SelectInputProps) => {
  const { t } = useTranslation();
  const currentUserQuery = useCurrentUserQuery();

  const options: { value: GameAppointmentStatus; label: string }[] = [
    { value: 'approved', label: t('msg_appointment_status_approved') },
    { value: 'declined', label: t('msg_appointment_status_declined') },
    { value: 'pending', label: t('msg_appointment_status_pending') },
  ];

  const { inputProps } = useInput(props);

  const { disabled: disabledProp, value, appointmentUserId, onChange } = props;

  const disabled =
    currentUserQuery.data && appointmentUserId
      ? !canChangeStatus(disabledProp || false, currentUserQuery.data, appointmentUserId)
      : true;

  const { isOpen, getMenuProps, getItemProps, highlightedIndex, getToggleButtonProps } = useSelect({
    items: options,
    id: inputProps.id,
    selectedItem: options.find((option) => option.value === value) || null,
    itemToString: (item) => item?.label || '',
    onSelectedItemChange: (item) => {
      if (!onChange) {
        return;
      }
      onChange(item.selectedItem.value);
    },
  });

  return (
    <div className="relative flex w-full cursor-pointer items-center">
      <div
        {...getToggleButtonProps({
          disabled,
          onClick: (event) => {
            event.stopPropagation();
          },
        })}
      >
        {getAppointmentStatusIcon(value, disabled)}
      </div>
      <div {...getMenuProps()}>
        {isOpen && options.length && (
          <ul
            className="absolute z-10 w-32 overflow-y-auto rounded border border-stone-300 bg-white"
            style={{ maxHeight: `${menuItemHeight * menuItemCount}px` }}
          >
            {options.map((item, index) => (
              <li
                key={index}
                {...getItemProps({
                  index,
                  item,
                  onClick: (event) => {
                    event.stopPropagation();
                  },
                })}
                className={cn(
                  'overflow-x-hidden text-ellipsis p-2 text-sm font-light text-slate-900 hover:cursor-pointer hover:bg-blue-50',
                  {
                    'bg-grey-300': highlightedIndex === index,
                  },
                )}
              >
                <span>{item.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AppointmentStatusSelectInput;
