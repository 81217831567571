import { Link } from '@tanstack/react-router';
import { useSelect } from 'downshift';
import type React from 'react';

import DoubleArrowDown from '../../assets/double-arrow-down.svg?react';
import type { Permission } from '../../hooks/useCheckPermission';
import useCheckPermission from '../../hooks/useCheckPermission';
import { cn } from '../../utils/styles';
import Tooltip from '../tooltip';

type TableAction = {
  label: React.ReactNode;
  permissions: Permission[];
  tooltip: string;
} & ({ type: 'button'; onClick: () => void } | { type: 'link'; route: string; onClick?: () => void });

type TableActionProps = {
  actions: TableAction[];
  resizeOn?: number;
};

const TableActionWithDropdown = ({ actions }: TableActionProps) => {
  const { isOpen, getMenuProps, getToggleButtonProps } = useSelect({ items: actions });

  return (
    <div className="flex justify-end">
      <div
        {...getToggleButtonProps({
          onClick: (event) => {
            event.stopPropagation();
          },
        })}
        className="flex items-center justify-center rounded-lg border border-stroke p-2 hover:bg-gray"
      >
        <DoubleArrowDown />
      </div>
      <div {...getMenuProps()} className="relative">
        {isOpen && (
          <ul className="absolute right-0 z-10 w-32 min-w-[200px] overflow-y-auto overflow-x-hidden rounded-lg border border-stone-300 bg-white">
            {actions.map((action, index) => {
              const className = 'flex items-center gap-2 p-2';

              const content = (
                <>
                  <div className="w-[20px]">{action.label}</div> <p className="truncate">{action.tooltip}</p>
                </>
              );

              return (
                <li key={index} className="hover:bg-gray">
                  {action.type === 'link' ? (
                    <Link
                      to={action.route}
                      className={className}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      {content}
                    </Link>
                  ) : (
                    <button
                      className={className}
                      onClick={(event) => {
                        event.stopPropagation();
                        action.onClick();
                      }}
                    >
                      {content}
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

const TableActions = ({ actions, resizeOn = 3 }: TableActionProps) => {
  const { hasAll } = useCheckPermission();

  const actionsToRender = actions.filter((item) => {
    return hasAll(item.permissions);
  });

  if (actions.length > resizeOn) {
    return <TableActionWithDropdown actions={actionsToRender} />;
  }

  return (
    <div className="flex w-full justify-end">
      <div className="inline-flex rounded-md" role="group">
        {actionsToRender.map((item, index) => {
          const classNames = cn(
            'border border-stroke px-2 py-2 hover:bg-gray flex justify-center items-center',
            index === 0 && 'rounded-s-lg',
            index !== 0 && 'border-l-0',
            index === actions.length - 1 && 'rounded-e-lg',
          );

          if (item.type === 'link') {
            return (
              <Link
                to={item.route}
                className={classNames}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                key={index}
              >
                <Tooltip content={item.tooltip}>{item.label}</Tooltip>
              </Link>
            );
          }

          return (
            <button
              className={classNames}
              onClick={(event) => {
                event.stopPropagation();
                item.onClick();
              }}
              key={index}
            >
              <Tooltip content={item.tooltip}>{item.label}</Tooltip>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TableActions;
