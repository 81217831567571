import { addHours, format } from 'date-fns';

import AddToCalendarModal from '@/components/modals/add-to-calendar-modal';
import { useModal } from '@/components/modals/modal';

type Event = {
  startTime: Date;
  title: string;
  description: string;
  location: string;
};

const getRandomKey = () => {
  const n = Math.floor(Math.random() * 999999999999).toString();
  return new Date().getTime().toString() + '_' + n;
};

const formatTime = (date: Date) => {
  return format(date, "yyyyMMdd'T'HHmmss");
};

const getUrl = (type: 'google' | 'outlookcom' | 'default', event: Event) => {
  let calendarUrl = '';
  const endTime = addHours(event.startTime, 2);

  switch (type) {
    case 'google':
      calendarUrl = 'https://calendar.google.com/calendar/render';
      calendarUrl += '?action=TEMPLATE';
      calendarUrl += '&dates=' + formatTime(event.startTime);
      calendarUrl += '/' + formatTime(endTime);
      calendarUrl += '&location=' + encodeURIComponent(event.location);
      calendarUrl += '&text=' + encodeURIComponent(event.title);
      calendarUrl += '&details=' + encodeURIComponent(event.description);
      break;

    case 'outlookcom':
      calendarUrl = 'https://outlook.live.com/owa/?rru=addevent';
      calendarUrl += '&startdt=' + formatTime(event.startTime);
      calendarUrl += '&enddt=' + formatTime(endTime);
      calendarUrl += '&subject=' + encodeURIComponent(event.title);
      calendarUrl += '&location=' + encodeURIComponent(event.location);
      calendarUrl += '&body=' + encodeURIComponent(event.description);
      calendarUrl += '&allday=false';
      calendarUrl += '&uid=' + getRandomKey();
      calendarUrl += '&path=/calendar/view/Month';
      break;

    default:
      calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + formatTime(event.startTime),
        'DTEND:' + formatTime(endTime),
        'SUMMARY:' + event.title,
        'DESCRIPTION:' + event.description,
        'LOCATION:' + event.location,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');
  }

  return calendarUrl;
};

const useAllToCalendar = () => {
  const { showModal } = useModal();

  const addToCalendar = async (event: Event) => {
    const result = await showModal({
      component: AddToCalendarModal,
      props: {},
    });

    console.log(result);

    if (result.action === 'CLOSE') {
      return;
    }

    const url = getUrl(result.provider, event);
    if (url.startsWith('data') || url.startsWith('BEGIN')) {
      const filename = 'download.ics';
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(url, '_blank');
    }
  };

  return {
    addToCalendar,
  };
};

export default useAllToCalendar;
