import { useMutation } from '@tanstack/react-query';

import { publicApiInstance } from '@/utils/api';

export type LoginData = {
  username: string;
  password: string;
};
export type ForgotPasswordData = {
  email: string;
};

export type LoginResponseData = {
  token: string;
  refresh: string;
};

export const loginUser = async (data: LoginData) => {
  const res = await publicApiInstance.post<LoginResponseData>('/api/auth', data);
  return res.data;
};

export const forgotPassword = (data: ForgotPasswordData) => {
  return publicApiInstance.post<{}>('/api/user/forgot-password', data);
};
export const changePassword = (data: { password: string; passwordRepeat: string; id: number }) => {
  return publicApiInstance.put<{}>(`/api/service/user/password/${data.id}`, data);
};

export const useLoginMutation = () =>
  useMutation({
    mutationFn: loginUser,
  });

export const useForgotPasswordMutation = () =>
  useMutation({
    mutationFn: forgotPassword,
  });

export const useChangePasswordMutation = () =>
  useMutation({
    mutationFn: changePassword,
  });

export const logout = () => {
  return publicApiInstance.get<{}>(`/api/logout`);
};
export const useLogoutMutation = () =>
  useMutation({
    mutationFn: logout,
  });
