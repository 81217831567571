import { format } from 'date-fns';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';

import { DATE_FORMATS } from '../../config/constants';
import useCheckPermission from '../../hooks/useCheckPermission';
import type { Post } from '../../services/post';
import { useDeletePostMutation } from '../../services/post';
import { useTranslation } from '../../translations/useTranslation';
import { shortenText } from '../../utils/string';
import { toastSuccess } from '../../utils/toast';
import { handleMutationError } from '../../utils/util';
import Button from '../button/button';
import ButtonGroup from '../button/button-group';
import { useModal } from '../modals/modal';
import PostModal from '../modals/post-modal';

const PostItem = ({ post }: { post: Post }) => {
  const text = DOMPurify.sanitize(post.content, { ALLOWED_TAGS: [] });
  const { showModal } = useModal();
  const { t } = useTranslation();
  const deletePostMutation = useDeletePostMutation();
  const { hasAll } = useCheckPermission();

  return (
    <div className="flex w-full flex-col gap-2 border-b border-stroke pb-2 dark:border-strokedark">
      <div className="flex w-full flex-col gap-1">
        <h4 className="text-title-xsm font-bold text-black dark:text-white">{post.title}</h4>
        <div>{format(post.created_at, DATE_FORMATS.DEFAULT)}</div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: shortenText(text, 150),
        }}
      ></div>
      <ButtonGroup>
        <Button
          size="xs"
          onClick={async () => {
            await showModal({
              component: PostModal,
              props: {
                post,
              },
            });
          }}
        >
          {t('msg_button_preview')}
        </Button>
        {hasAll(['admin']) && (
          <Button.Link size="xs" href={`/posts/edit/${post.id}`}>
            {t('msg_button_edit')}
          </Button.Link>
        )}
        {hasAll(['admin']) && (
          <Button
            size="xs"
            onClick={async () => {
              try {
                if (confirm(t('msg_confirm_delete'))) {
                  await deletePostMutation.mutateAsync({ id: post.id });
                  toastSuccess(t('msg_toast_deleted_successful'));
                }
              } catch (err) {
                handleMutationError(err, t);
              }
            }}
          >
            {t('msg_button_delete')}
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default PostItem;
