import type { QueryFunction, QueryKey } from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DEFAULT_PAGE_SIZE } from '../config/constants';
import { apiInstance } from '../utils/api';
import type { PaginatedResult, PaginationParams } from '../utils/types';

import type { GameAppointment } from './appointments';
import { queryKeys as gameAppointmentQueryKeys } from './appointments';
import type { League } from './league';

export const queryKeys = {
  base: ['games'] as const,
  myGames: (params: BaseGameQueryParams) => [...queryKeys.base, 'myGames', params] as const,
  game: (id: string) => [...queryKeys.base, 'game', id] as const,
  unconfirmedGames: (params: BaseGameQueryParams) => [...queryKeys.base, 'unconfirmed', params] as const,
};
export type LeagueTypeFilter = 'normal' | 'mini' | 'all';

export type BaseGameQueryParams = {
  startDate: string;
  leagueType: LeagueTypeFilter;
};

export type AllGamesQueryParams = {
  leagueId?: number | null;
} & BaseGameQueryParams;

export type Game = {
  league: League;
  league_id: number;
  commissioner_remotely: boolean;
  modified_text: string;
  tv_broadcast: boolean;
  game_number: number;
  modified: boolean;
  canceled: boolean;
  is_trip: boolean;
  team2: string;
  team1: string;
  address: string;
  event_date: string;
  id: number;
  updated_at: string;
  created_at: string;
};

export type GameInList = {
  game_appointments: GameAppointment[];
} & Game;

type CreateGamePayload = {
  league_id: number;
  game_number: number;
  team1: string;
  team2: string;
  event_date: string;
  address: string;
  is_trip: boolean;
  tv_broadcast: boolean;
  modified: boolean;
  canceled: boolean;
  commissioner_remotely: boolean;
  modified_text: string;
};

export const useGamesListInfiniteQuery = (
  queryKey: QueryKey,
  queryFn: QueryFunction<PaginatedResult<GameInList>, QueryKey, number>,
) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey,
    queryFn: async (params) => {
      const result = await queryFn(params);
      result.collection.forEach((game) => {
        queryClient.setQueryData(gameAppointmentQueryKeys.gameAppointments(game.id), game.game_appointments);
      });

      return result;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (pages.length * DEFAULT_PAGE_SIZE > lastPage.itemsCount) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};

export const fetchMyGames = async (params: BaseGameQueryParams & PaginationParams) => {
  const response = await apiInstance.get<PaginatedResult<GameInList>>(`/api/service/games/my/paginated`, {
    params,
  });

  return response.data;
};

export const useMyGamesQuery = (params: BaseGameQueryParams) => {
  return useGamesListInfiniteQuery(queryKeys.myGames(params), ({ pageParam = 0 }) =>
    fetchMyGames({ ...params, size: DEFAULT_PAGE_SIZE, page: pageParam }),
  );
};

export const fetchGame = async (gameId: string) => {
  const response = await apiInstance.get<Game>(`/api/service/game/data/${gameId}`);
  return response.data;
};

export const useGameQuery = (gameId: string) => {
  return useQuery({
    queryKey: queryKeys.game(gameId),
    queryFn: () => fetchGame(gameId),
  });
};

export const createGame = async (data: CreateGamePayload) => {
  const res = await apiInstance.post<Game>('/api/service/game/data/', data);
  return res.data;
};
export const updateGame = async (data: CreateGamePayload & { id: number }) => {
  const res = await apiInstance.put<Game>(`/api/service/game/data/${data.id}`, data);
  return res.data;
};
export const useCreateGameMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createGame,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};
export const useUpdateGameMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateGame,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};

export const fetchAllGames = async (params: AllGamesQueryParams & PaginationParams) => {
  const response = await apiInstance.get<PaginatedResult<GameInList>>(`/api/service/games/all/paginated`, {
    params,
  });

  return response.data;
};

export const useAllGamesQuery = (params: AllGamesQueryParams) => {
  return useGamesListInfiniteQuery(queryKeys.myGames(params), ({ pageParam = 0 }) =>
    fetchAllGames({ ...params, size: DEFAULT_PAGE_SIZE, page: pageParam }),
  );
};

export const fetchUnconfirmedGames = async (params: BaseGameQueryParams & PaginationParams) => {
  const response = await apiInstance.get<PaginatedResult<GameInList>>(`/api/service/games/unconfirmed/paginated`, {
    params,
  });

  return response.data;
};

export const useUnconfirmedGamesQuery = (params: BaseGameQueryParams) => {
  return useGamesListInfiniteQuery(queryKeys.unconfirmedGames(params), ({ pageParam = 0 }) =>
    fetchUnconfirmedGames({ ...params, size: DEFAULT_PAGE_SIZE, page: pageParam }),
  );
};
export const deleteGame = async ({ id }: { id: number }) => {
  const res = await apiInstance.delete<{}>(`/api/service/game/data/${id}`);
  return res.data;
};
export const useDeleteGameMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteGame,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};

export const sendGameTripInfo = async (data: { game_id: number; content: string }) => {
  const res = await apiInstance.post<{}>(`/api/service/game-trip-info`, data);
  return res.data;
};

export const useSendGameTripInfoMutation = () => {
  return useMutation({
    mutationFn: sendGameTripInfo,
  });
};
