import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DEFAULT_PAGE_SIZE } from '../config/constants';
import { apiInstance } from '../utils/api';
import type { PaginatedResult, PaginationParams } from '../utils/types';

import type { Availability } from './availability';
export const queryKeys = {
  base: ['users'],
  currentUser: ['currentUser'],
  users: (params: UserQueryParams) => ['users', params],
  user: (userId: string) => ['users', userId],
  userNotifications: () => [...queryKeys.base, 'notifications'],
  userStatistics: () => [...queryKeys.base, 'userStatistics'],
  userSuggestion: (params: UserSuggestionQueryParams) => ['users', 'suggestion', params],
};
export type UserQueryParams = {
  user_type: UserType;
  search_text: string;
};
export type UserType = 'referee' | 'secretary' | 'commissioner';
export type UserCategory = 'none' | 'fiba' | 'lkl' | 'nkl' | 'rkl' | 'national';
export type User = {
  id: number;
  email: string;
  short_name: string;
  user_type: UserType;
  account_number: string;
  address: string;
  category: UserCategory;
  certificate_until: string;
  daily_mail: boolean;
  first_name: string;
  invoice_number: number;
  invoice_seller_info: string;
  invoice_serial: string;
  is_association_member: boolean;
  last_name: string;
  person_code: string;
  phone_number: string;
  suspended_until: string;
  is_admin: boolean;
};

type UserSuggestionQueryParams = {
  query: string;
  gameId: number;
  type: UserType;
  eventDate?: string; //TODO make mandatory
};

type CreateUserPayload = {
  first_name: string;
  last_name: string;
  person_code: string;
  account_number: string;
  email: string;
  phone_number: string;
  address: string;
  daily_mail: boolean;
  invoice_serial: string;
  invoice_number: number;
  invoice_seller_info: string;
  user_type: UserType;
  category: UserCategory;
  is_association_member: boolean;
  suspended_until: string;
  certificate_until: string;
};
export const fetchCurrentUser = async () => {
  const response = await apiInstance.get<User & { is_admin: boolean }>(`/api/user/me`);
  return response.data;
};

export const useCurrentUserQuery = () => {
  return useQuery({
    queryKey: queryKeys.currentUser,
    queryFn: fetchCurrentUser,
  });
};
export type SuggestedUser = { user_availabilities?: Availability[] } & User;
export const fetchCurrentSuggestion = async (params: UserSuggestionQueryParams) => {
  const response = await apiInstance.get<SuggestedUser[]>('/api/service/user/suggestions', {
    params,
  });

  return response.data;
};
export const useUserSuggestionQuery = (params: UserSuggestionQueryParams) => {
  return useQuery({
    queryKey: queryKeys.userSuggestion(params),
    queryFn: () => fetchCurrentSuggestion(params),
  });
};

export const fetchUsers = async (params: UserQueryParams & PaginationParams) => {
  const response = await apiInstance.get<PaginatedResult<User>>(`/api/service/users/all/paginated`, {
    params,
  });

  return response.data;
};

export const useUsersQuery = (params: UserQueryParams) => {
  return useInfiniteQuery({
    queryKey: queryKeys.users(params),
    queryFn: ({ pageParam = 0 }) => fetchUsers({ ...params, size: DEFAULT_PAGE_SIZE, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (pages.length * DEFAULT_PAGE_SIZE > lastPage.itemsCount) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};
export const fetchUser = async (userId: string) => {
  const response = await apiInstance.get<User>(`/api/service/user/data/${userId}`);
  return response.data;
};

export const useUserQuery = (userId: string) => {
  return useQuery({
    queryKey: queryKeys.user(userId),
    queryFn: () => fetchUser(userId),
  });
};

export const updateUser = async (data: CreateUserPayload & { id: string }) => {
  const res = await apiInstance.put<User>(`/api/service/user/data/${data.id}`, data);
  return res.data;
};
export const createUser = async (data: CreateUserPayload) => {
  const res = await apiInstance.post<User>('/api/service/user/data', data);
  return res.data;
};
export const deleteUser = async ({ id }: { id: number }) => {
  const res = await apiInstance.delete<{}>(`/api/service/users/all/paginated/${id}`);
  return res.data;
};
export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};
export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};
export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};

export const fetchUserNotifications = async () => {
  const response = await apiInstance.get<{ unconfirmed_appointments: number }>(`/api/app/user/data`);
  return response.data;
};

export const useUserNotificationsQuery = () => {
  return useQuery({
    queryKey: queryKeys.userNotifications(),
    queryFn: () => fetchUserNotifications(),
  });
};

export const fetchUserMonthStatistics = async () => {
  const response = await apiInstance.get<
    {
      appointments_count: string;
      league_code: string;
      league_group_id: number;
      league_id: number;
      league_name: string;
      split_rankings: boolean;
      summed_driver_ranking: string;
      summed_original_ranking: string;
      summed_ranking: string;
    }[]
  >(`/api/dashboard/charts/data`);

  return response.data;
};

export const useUserMonthStatisticsQuery = () => {
  return useQuery({
    queryKey: queryKeys.userStatistics(),
    queryFn: () => fetchUserMonthStatistics(),
  });
};
