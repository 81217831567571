import React, { useEffect } from 'react';

import { usePage } from '../context/page';
import type { Permission } from '../hooks/useCheckPermission';
import useCheckPermission from '../hooks/useCheckPermission';
import type { MessageKey } from '../translations/translation-types';
import { useTranslation } from '../translations/useTranslation';

import Box, { BoxContent } from './box';

const NoPermission = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <BoxContent>{t('msg_no_permissions_title')}</BoxContent>
    </Box>
  );
};

const wrapPage = (
  WrappedComponent: () => JSX.Element,
  titleKey: MessageKey | undefined,
  permissions: Permission[] = ['user'],
) => {
  const ComponentWithExtraInfo = () => {
    const { t } = useTranslation();
    const { setTitle } = usePage();
    useEffect(() => {
      const title = titleKey ? `${t(titleKey as any)}` : '';
      setTitle(title);
      document.title = title ? `${title} | ${t('msg_site_title')}` : `${t('msg_site_title')}`;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { hasAll } = useCheckPermission();
    if (hasAll(permissions)) {
      return <WrappedComponent />;
    }
    return <NoPermission />;
  };

  return ComponentWithExtraInfo;
};

export default wrapPage;
