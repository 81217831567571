import { useCallback, useMemo } from 'react';

import { useAppConfigQuery } from '../services/app';
import { useCurrentUserQuery } from '../services/user';

export type Permission =
  | 'user'
  | 'user:remove'
  | 'admin'
  | 'invoices'
  | 'tests'
  | 'video'
  | 'evaluation'
  | 'documents'
  | 'league-groups'
  | 'trip-ranking'
  | 'trip-mail'
  | 'expenses'
  | 'balance'
  | 'expenses:report'
  | 'schedule:full'
  | 'availability';

const useCheckPermission = () => {
  const appConfigQuery = useAppConfigQuery();
  const currentUserQuery = useCurrentUserQuery();

  const user = currentUserQuery.data;
  const appConfig = appConfigQuery.data;

  const currentPermissions: (Permission | undefined)[] = useMemo(() => {
    return [
      user ? 'user' : undefined,
      user?.is_admin ? 'admin' : undefined,
      appConfig?.features.user.remove ? 'user:remove' : undefined,
      appConfig?.features.invoices ? 'invoices' : undefined,
      appConfig?.features.tests.enabled ? 'tests' : undefined,
      appConfig?.features.video ? 'video' : undefined,
      appConfig?.features.game.evaluation ? 'evaluation' : undefined,
      appConfig?.features.documents ? 'documents' : undefined,
      appConfig?.features.user.availability ? 'availability' : undefined,
      appConfig?.features.league.smallLeague ? 'league-groups' : undefined,
      appConfig?.features.game.tripRanking ? 'trip-ranking' : undefined,
      appConfig?.features.game.tripMail ? 'trip-mail' : undefined,
      appConfig?.features.user.expensesReport ? 'expenses:report' : undefined,
      appConfig?.features.game.expenses ? 'expenses' : undefined,
      appConfig?.features.reports.balanceCalculations ? 'balance' : undefined,
      appConfig?.features.appointments.fullyVisible &&
      user &&
      appConfig.features.appointments.restrictFullVisibilityForUsers.includes(user.id)
        ? undefined
        : 'schedule:full',
    ];
  }, [appConfig, user]);

  const hasAll = useCallback(
    (requiredPermissions: Permission[]): boolean => {
      return requiredPermissions.every((permission) => currentPermissions.includes(permission));
    },
    [currentPermissions],
  );

  const hasAny = useCallback(
    (requiredPermissions: Permission[]): boolean => {
      return requiredPermissions.some((permission) => currentPermissions.includes(permission));
    },
    [currentPermissions],
  );

  return { hasAll, hasAny };
};

export default useCheckPermission;
