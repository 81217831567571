import { format } from 'date-fns';
import React from 'react';

import { DATE_FORMATS } from '../../config/constants';
import type { GameInList } from '../../services/game';
import type { Translator } from '../../translations/translation-types';
import { filterPersons, filterReferees, filterSecretary } from '../../utils/filters';
import type { Column } from '../table/table';

import GameAppointments from './game-appointments-display';

export const renderGameNumberColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_game_number'),
    size: 1,
    accessor: (item) => `${item.game_number}`,
  };
};

export const renderGameLeagueColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_league'),
    size: 2,
    accessor: (item) => item.league.code.toUpperCase(),
  };
};
export const renderGameEventDateColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_time'),
    size: 2,
    accessor: (item) => format(item.event_date, DATE_FORMATS.TIME),
  };
};
export const renderTeamsColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_teams'),
    size: 3,
    accessor: (item) => `${item.team1} - ${item.team2}`,
  };
};
export const renderAddressColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_address'),
    size: 4,
    accessor: (item) => item.address,
  };
};
export const renderRefereesColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_referees'),
    size: 3,
    Cell: (item) => {
      return <GameAppointments gameId={item.id} filter={filterReferees} />;
    },
  };
};
export const renderSecretaryColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_secretary'),
    size: 3,
    Cell: (item) => {
      return <GameAppointments gameId={item.id} filter={filterSecretary} />;
    },
  };
};
export const renderParticipantsColumn = (t: Translator): Column<GameInList> => {
  return {
    Header: t('msg_table_header_other_participants'),
    size: 3,
    Cell: (item) => {
      return <GameAppointments gameId={item.id} filter={filterPersons} />;
    },
  };
};
