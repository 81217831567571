import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { enableLanguage } from '../translations/component-translations';
import { apiInstance } from '../utils/api';

export const queryKeys = {
  appConfig: ['appConfig'],
  appStats: ['appStats'],
};
export type AppConfig = {
  site: string;
  lang: 'lt' | 'en';
  translations: Record<string, string> | undefined;
  features: {
    appointments: {
      commissioner: boolean;
      secretary: boolean;
      referee: boolean;
      fullyVisible: boolean;
      restrictFullVisibilityForUsers: number[];
      extraSecretary: boolean;
      delegate: boolean;
      commissionerRemotely: boolean;
      availabilityRestrictions: boolean;
      restrictSecretarySmallLeague: boolean;
      resetOnGameInfoChange: boolean;
    };
    game: {
      gameNumber: boolean;
      tripMail: boolean;
      evaluation: boolean;
      tvBroadcast: boolean;
      autoChangeMail: boolean;
      expenses: boolean;
      tripRanking: boolean;
    };
    user: {
      remove: boolean;
      administration: boolean;
      publicData: boolean;
      availability: boolean;
      expensesReport: boolean;
      picture: boolean;
    };
    reports: {
      balanceCalculations: boolean;
    };
    invoices: boolean;
    dashboardPopup: boolean;
    tests: {
      enabled: boolean;
      enableTestSolveUpdate: boolean;
    };
    video: boolean;
    documents: boolean;
    league: {
      smallLeague: boolean;
      leagueGroups: boolean;
    };
  };
  invoiceBuyers: { id: string; name: string; description: string }[];
  colors: {
    mainColor: string;
  };
};
export type AppStatistics = {
  gamesCount: number;
  seasonGamesCount: number;
  leaguesCount: number;
  users: { refsCount: number; secretaryCount: number; commissionersCount: number };
};
export const fetchAppConfig = async () => {
  const response = await apiInstance.get<AppConfig>(`/api/app-config`);
  return response.data;
};

export const useAppConfigQuery = () => {
  const { i18n } = useTranslation();

  return useQuery({
    queryKey: queryKeys.appConfig,
    staleTime: 20 * 60 * 1000,
    queryFn: async () => {
      const appConfig = await fetchAppConfig();
      if (appConfig.translations) {
        i18n.addResourceBundle(appConfig.lang, 'translation', appConfig.translations, true, true);
      }
      await i18n.changeLanguage(appConfig.lang);
      enableLanguage(appConfig.lang);
      return appConfig;
    },
  });
};

export const fetchAppStats = async () => {
  const response = await apiInstance.get<AppStatistics>(`/api/statistics`);
  return response.data;
};

export const useAppStatisticsQuery = () => {
  return useQuery({
    queryKey: queryKeys.appStats,
    queryFn: () => fetchAppStats(),
  });
};
