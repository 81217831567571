import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Header from '../../components/header';
import FullScreenLoader from '../../components/loading/full-screen-loader';
import Sidebar from '../../components/sidebar';
import { useAuth } from '../../context/auth';

export const Route = createFileRoute('/_auth')({
  // beforeLoad: ({ context, location }) => {
  //   if (context.auth.isReady && !context.auth.isAuthenticated) {
  //     throw redirect({
  //       to: '/login',
  //       search: {
  //         redirect: location.href,
  //       },
  //     });
  //   }
  // },
  component: AuthLayout,
});

function AuthLayout() {
  // const router = useRouter();
  // const navigate = Route.useNavigate();
  const auth = useAuth();

  const isLG = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const [sidebarOpen, setSidebarOpen] = useState(isLG);

  if (!auth.isReady) {
    return <FullScreenLoader />;
  }
  if (!auth.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // const handleLogout = () => {
  //   if (window.confirm('Are you sure you want to logout?')) {
  //     auth.logout().then(() => {
  //       router.invalidate().finally(() => {
  //         navigate({ to: '/' });
  //       });
  //     });
  //   }
  // };

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} isLGScreen={isLG} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-x-hidden overflow-y-scroll">
          {/* <!-- ===== Header Start ===== --> */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className="mx-auto w-full p-4">
            <Outlet />
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
}
