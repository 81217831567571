import type { GameAppointment } from '../services/appointments';
import type { League } from '../services/league';

export const filterReferees = (gameAppointment: GameAppointment) => {
  return ['referee_one', 'referee_two', 'referee_three'].includes(gameAppointment.user_type_in_event);
};
export const filterSecretary = (gameAppointment: GameAppointment) => {
  return [
    'secretary_score',
    'secretary_time',
    'secretary_4',
    'secretary_5',
    'secretary_information',
    'secretary_statistics',
    'secretary_statistics_2',
    'secretary_statistics_3',
  ].includes(gameAppointment.user_type_in_event);
};
export const filterPersons = (gameAppointment: GameAppointment) => {
  return ['delegate', 'commissioner'].includes(gameAppointment.user_type_in_event);
};

export const filterOutReferees = (gameAppointment: GameAppointment) => {
  return !['referee_one', 'referee_two', 'referee_three'].includes(gameAppointment.user_type_in_event);
};

export const filterNonArchivedLeagues = (item: League) => {
  return !item.league_group || !item.league_group.is_archived;
};
