import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DEFAULT_PAGE_SIZE } from '../config/constants';
import { apiInstance } from '../utils/api';
import type { PaginatedResult, PaginationParams } from '../utils/types';

export type Post = {
  user_id: number;
  updated_at: string;
  created_at: string;
  important: boolean;
  content: string;
  title: string;
  id: number;
};
export const queryKeys = {
  posts: (pageSize: number) => [...queryKeys.base, 'posts', pageSize],
  post: (id: string) => [...queryKeys.base, 'post', id],
  base: ['post'],
};
export const fetchPosts = async (params: PaginationParams) => {
  const response = await apiInstance.get<PaginatedResult<Post>>(`/api/service/posts/all`, {
    params,
  });

  return response.data;
};

export const usePostsQuery = (pageSize = DEFAULT_PAGE_SIZE) => {
  return useInfiniteQuery({
    queryKey: queryKeys.posts(pageSize),
    queryFn: ({ pageParam = 0 }) => fetchPosts({ size: pageSize, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (pages.length * DEFAULT_PAGE_SIZE > lastPage.itemsCount) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};
type CreateGamePayload = {
  content: string;
  important: boolean;
  send_mail: boolean;
  title: string;
};
export const createPost = async (data: CreateGamePayload) => {
  const res = await apiInstance.post<Post>('/api/service/post/data', data);
  return res.data;
};

export const useCreatePostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};

export const updatePost = async (data: CreateGamePayload & { id: string }) => {
  const res = await apiInstance.put<Post>(`/api/service/post/data/${data.id}`, data);
  return res.data;
};

export const useUpdatePostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};
export const fetchPost = async (postId: string) => {
  const response = await apiInstance.get<Post>(`/api/service/post/data/${postId}`);
  return response.data;
};

export const usePostQuery = (postId: string) => {
  return useQuery({
    queryKey: queryKeys.post(postId),
    queryFn: () => fetchPost(postId),
  });
};
export const deletePost = async ({ id }: { id: number }) => {
  const res = await apiInstance.delete<{}>(`/api/service/post/data/${id}`);
  return res.data;
};
export const useDeletePostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.base });
    },
  });
};
