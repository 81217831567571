import { Link } from '@tanstack/react-router';
import React from 'react';

import CalendarIcon from '../../assets/calendar.svg?react';
import Hamburger from '../../assets/hamburger.svg?react';
import { usePage } from '../../context/page';
import { useUserNotificationsQuery } from '../../services/user';

import DropdownUser from './dropdown-user';

const Header = (props: { sidebarOpen: string | boolean | undefined; setSidebarOpen: (arg0: boolean) => void }) => {
  const { title } = usePage();
  const userNotificationsQuery = useUserNotificationsQuery();

  const changeSidebarOpen = () => {
    props.setSidebarOpen(!props.sidebarOpen);
  };

  return (
    <header className="sticky top-0 z-99 flex w-full bg-boxdark drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex grow items-center justify-between gap-2 p-3 shadow-2 md:px-6">
        <button className="hidden lg:block" onClick={changeSidebarOpen}>
          <Hamburger className="size-7 fill-white" />
        </button>
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <button
            aria-controls="sidebar"
            onClick={changeSidebarOpen}
            className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark"
          >
            <span className="relative block size-5.5 cursor-pointer">
              <span className="absolute right-0 size-full">
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!w-full delay-300'
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!w-full delay-300'
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!w-full delay-500'
                  }`}
                ></span>
              </span>
              <span className="absolute right-0 size-full rotate-45">
                <span
                  className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!h-0 !delay-[0]'
                  }`}
                ></span>
                <span
                  className={`absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!h-0 !delay-200'
                  }`}
                ></span>
              </span>
            </span>
          </button>
        </div>
        <div className="flex w-full flex-wrap items-center gap-2 text-xl text-white md:text-2xl">{title}</div>
        {/*<ul className="flex items-center gap-2 2xsm:gap-4">*/}
        {/*  <DarkModeSwitcher />*/}
        {/*</ul>*/}
        <div className="flex items-center justify-center gap-2">
          {userNotificationsQuery.data && (
            <Link
              to="/schedule/my"
              className="flex items-center justify-center gap-1 rounded-full border border-stroke p-1 text-xs text-white"
            >
              <CalendarIcon width={14} height={14} className="fill-white" />
              {userNotificationsQuery.data.unconfirmed_appointments}
            </Link>
          )}
          <DropdownUser />
        </div>
      </div>
    </header>
  );
};

export default Header;
