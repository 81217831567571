import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import Button from './components/button/button';
import EnvConfig from './config/env';
import { AuthProvider, useAuth } from './context/auth';
import { PageContextProvider } from './context/page';
// eslint-disable-next-line import/order
import { routeTree } from './routeTree.gen';

import '@/translations/translations-config';

import { useAppConfigQuery } from './services/app';
import { useTranslation } from './translations/useTranslation';

import FullScreenLoader from '@/components/loading/full-screen-loader';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  basepath: EnvConfig.IS_PRODUCTION ? '/new' : '/',
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

declare module '@tanstack/react-router' {
  //eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
}
if (EnvConfig.IS_SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://180a2de92e5bd1e4c41690ec36e5641e@o4507571029540864.ingest.de.sentry.io/4507571031441488',
    integrations: [],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  const auth = useAuth();
  const { t } = useTranslation();

  const appConfigQuery = useAppConfigQuery();
  useEffect(() => {
    if (appConfigQuery.data) {
      document.body.style.setProperty('--primary-color', appConfigQuery.data.colors.mainColor);
    }
  }, [appConfigQuery.data]);

  if (appConfigQuery.isLoading) {
    return <FullScreenLoader />;
  }
  if (appConfigQuery.isError) {
    return (
      <div className="my-5 flex flex-col items-center justify-center gap-2">
        {t('msg_error_unexpected')}.
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('msg_btn_reload')}
        </Button>
      </div>
    );
  }

  return <RouterProvider router={router} context={{ auth }} />;
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Suspense fallback={<FullScreenLoader />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <PageContextProvider>
            <ToastContainer />
            <App />
          </PageContextProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>,
);
