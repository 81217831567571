import type { ReactNode } from 'react';
import { useId } from 'react';
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Tooltip = ({ children, content }: { children: ReactNode; content: string }) => {
  const id = useId();

  return (
    <>
      <span data-tooltip-id={id}>{children}</span>
      <ReactTooltip id={`${id}`} content={content} />
    </>
  );
};

export default Tooltip;
