import axios from 'axios';

import EnvConfig from '../config/env';

const createApiInstance = (baseURL?: string) => {
  return axios.create({
    baseURL,
    withCredentials: true,
  });
};

export const apiInstance = createApiInstance(EnvConfig.API_BASE_URL);
export const publicApiInstance = createApiInstance(EnvConfig.API_BASE_URL);
