import * as React from "react";
const SvgOutlook = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 32 32", xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("title", null, "file_type_outlook"), /* @__PURE__ */ React.createElement("path", { d: "M19.484,7.937v5.477L21.4,14.619a.489.489,0,0,0,.21,0l8.238-5.554a1.174,1.174,0,0,0-.959-1.128Z", style: {
  fill: "#0072c6"
} }), /* @__PURE__ */ React.createElement("path", { d: "M19.484,15.457l1.747,1.2a.522.522,0,0,0,.543,0c-.3.181,8.073-5.378,8.073-5.378V21.345a1.408,1.408,0,0,1-1.49,1.555H19.483V15.457Z", style: {
  fill: "#0072c6"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.44,12.932a1.609,1.609,0,0,0-1.42.838,4.131,4.131,0,0,0-.526,2.218A4.05,4.05,0,0,0,9.02,18.2a1.6,1.6,0,0,0,2.771.022,4.014,4.014,0,0,0,.515-2.2,4.369,4.369,0,0,0-.5-2.281A1.536,1.536,0,0,0,10.44,12.932Z", style: {
  fill: "#0072c6"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.153,5.155V26.582L18.453,30V2ZM13.061,19.491a3.231,3.231,0,0,1-2.7,1.361,3.19,3.19,0,0,1-2.64-1.318A5.459,5.459,0,0,1,6.706,16.1a5.868,5.868,0,0,1,1.036-3.616A3.267,3.267,0,0,1,10.486,11.1a3.116,3.116,0,0,1,2.61,1.321,5.639,5.639,0,0,1,1,3.484A5.763,5.763,0,0,1,13.061,19.491Z", style: {
  fill: "#0072c6"
} }));
export default SvgOutlook;
