import type React from 'react';

const StatsBox = ({ label, result }: { label: string; result: number | string }) => {
  return (
    <div className="w-full overflow-hidden border border-stroke bg-white text-left align-bottom transition-all">
      <div className="bg-white p-2">
        <div className="flex items-center justify-center gap-1 text-center sm:text-left md:flex-col">
          <h3 className="text-sm font-medium leading-6">{label}</h3>
          <p className="text-3xl font-bold text-black">{result}</p>
        </div>
      </div>
    </div>
  );
};

export const StatsBoxWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col gap-2 md:flex-row">{children}</div>;
};

export default StatsBox;
