import { useNavigate } from '@tanstack/react-router';

const useNavigation = () => {
  const navigate = useNavigate();

  const navigateToGame = async (gameId: number) => {
    return navigate({
      to: '/games/$gameId',
      params: {
        gameId: `${gameId}`,
      },
    });
  };

  const navigateToLeague = async (leagueId: number) => {
    return navigate({
      to: '/leagues/$leagueId',
      params: {
        leagueId: `${leagueId}`,
      },
    });
  };

  return {
    navigateToGame,
    navigateToLeague,
  };
};

export default useNavigation;
