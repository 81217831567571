import type React from 'react';

import { useTranslation } from '../../translations/useTranslation';
import { cn } from '../../utils/styles';
import Button from '../button/button';
import LoadingSpinner from '../loading/loading-spinner';

import { SeparatorRow, TableRow } from './table-row';

type ColumnBase = {
  Header: string | React.ReactNode;
  size: number;
  maxWidth?: number;
  truncateText?: boolean;
};

type ColumnWithOnlyCell<TableItem> = ColumnBase & {
  Cell: (item: TableItem) => React.ReactNode;
};

type ColumnWithOnlyAccessor<TableItem> = ColumnBase & {
  accessor: (item: TableItem) => string;
};

type ColumnWithAccessorAndCell<TableItem> = ColumnBase & {
  accessor: (item: TableItem) => string;
  Cell: (item: TableItem) => React.ReactNode;
};
export type GroupedItem<TableItem extends object> =
  | { type: 'ITEM'; data: TableItem }
  | { type: 'SEPARATOR'; data: { title: string } };
export type TableProps<TableItem extends object> = {
  columns: Column<TableItem>[];
  data: TableItem[];
  loading?: boolean;
  rowStyling?: (data: TableItem) => string;
  onRowClick?: (index: TableItem) => void;

  rowGroupSeparator?: {
    groupBy: (data: TableItem[]) => GroupedItem<TableItem>[];
  };

  renderRow?: (props: {
    index: number;
    content: (column: Column<TableItem>) => React.ReactNode;
    item?: TableItem;
    renderRowDefault: (props: {
      index: number;
      content: (column: Column<TableItem>) => React.ReactNode;
      expandedItems: number[];
    }) => React.ReactNode;
  }) => React.ReactNode;
} & (
  | {
      hasNextPage: boolean;
      loadMore: () => void;
    }
  | {
      hasNextPage?: never;
      loadMore?: never;
    }
);

export type Column<TableItem> =
  | ColumnWithOnlyAccessor<TableItem>
  | ColumnWithOnlyCell<TableItem>
  | ColumnWithAccessorAndCell<TableItem>;

const Table = <TableItem extends object>({
  columns,
  data,
  rowGroupSeparator,
  loading,
  loadMore,
  hasNextPage,
  rowStyling,
  onRowClick,
}: TableProps<TableItem>) => {
  const tableSize = columns.reduce((result, item) => {
    return result + item.size;
  }, 0);

  const { t } = useTranslation();

  const renderRows = () => {
    if (!data.length && !loading) {
      return (
        <div className="flex items-center justify-center border-b border-stroke py-2">{t('msn_table_no_records')}</div>
      );
    }
    if (!data.length && loading) {
      return (
        <div className="mt-2 flex items-center justify-center">
          <LoadingSpinner height={40} />
        </div>
      );
    }
    if (rowGroupSeparator) {
      const groupedData = rowGroupSeparator.groupBy(data);
      return (
        <>
          {groupedData.map((item, key) => {
            if (item.type === 'ITEM') {
              const rowStyle = rowStyling ? rowStyling(item.data) : '';
              return (
                <TableRow
                  onRowClick={() => {
                    if (onRowClick) {
                      onRowClick(item.data);
                    }
                  }}
                  key={key}
                  columns={columns}
                  rowStyle={rowStyle}
                  content={(column) => {
                    const columnWithAccessor = 'accessor' in column;
                    return columnWithAccessor ? column.accessor(item.data) : column.Cell(item.data);
                  }}
                />
              );
            } else {
              return <SeparatorRow key={key} tableSize={tableSize} title={item.data.title} />;
            }
          })}
        </>
      );
    }
    return (
      <>
        {data.map((item, key) => {
          const rowStyle = rowStyling ? rowStyling(item) : '';
          return (
            <TableRow
              onRowClick={() => {
                if (onRowClick) {
                  onRowClick(item);
                }
              }}
              key={key}
              columns={columns}
              rowStyle={rowStyle}
              content={(column) => {
                const columnWithAccessor = 'accessor' in column;
                return columnWithAccessor ? column.accessor(item) : column.Cell(item);
              }}
            />
          );
        })}
      </>
    );
  };

  return (
    <div>
      <div
        className={cn(
          'hidden gap-1 border-stroke bg-gray p-1 dark:border-strokedark md:grid',
          `grid-cols-${tableSize}`,
        )}
      >
        {columns.map((column, index) => {
          return (
            <div className={cn('flex items-start', `col-span-${column.size}`)} key={index}>
              <p className="font-medium">{column.Header}</p>
            </div>
          );
        })}
      </div>
      {renderRows()}
      {hasNextPage && (
        <div className="mt-5 flex justify-center">
          <Button onClick={loadMore} loading={loading}>
            {t('msg_button_load_more')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Table;
