export const shortenText = (text: string, size: number) => {
  let croppedText = '';

  if (text.length > size) {
    croppedText = text.slice(0, size);
    const lastIndex = croppedText.lastIndexOf(' ');
    croppedText = croppedText.substring(0, lastIndex) + ' ...';
  } else {
    croppedText = text;
  }

  return croppedText;
};

export const moneyDisplay = (amount: number) => {
  return amount.toFixed(2);
};
