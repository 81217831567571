export const LOGO_URL = '/custom/images/logo.png';

export const DATE_FORMATS = {
  DEFAULT: 'yyyy-MM-dd',
  DEFAULT_WITH_TIME: 'yyyy-MM-dd HH:mm',
  TIME: 'HH:mm',
  DATE_WITH_DAY_TEXT: 'yyyy-MM-dd (cccc)',
};

export const DEFAULT_PAGE_SIZE = 30;
export const DEBOUNCE_DELAY_50 = 50;
export const DEBOUNCE_DELAY_200 = 200;
