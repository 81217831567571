import { createFileRoute, Link } from '@tanstack/react-router';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import EmailIcon from '../assets/email.svg?react';
import PasswordIcon from '../assets/password.svg?react';
import Box from '../components/box';
import Button from '../components/button/button';
import Error from '../components/inputs/error';
import TextInput from '../components/inputs/text-input';
import { LOGO_URL } from '../config/constants';
import { useAuth } from '../context/auth';
import { useLoginMutation } from '../services/auth';
import { mapToInputProps, submitHandler } from '../utils/form';
import { emailRule, requiredRule, validateRules } from '../utils/validation';

import { useTranslation } from '@/translations/useTranslation';

type LoginFormValues = {
  username: string;
  password: string;
};

const LoginPage = () => {
  const { t } = useTranslation();
  const loginUserMutation = useLoginMutation();
  const { login } = useAuth();
  const navigate = Route.useNavigate();

  const form = useForm<LoginFormValues>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  return (
    <Box>
      <div className="mb-5 flex justify-center">
        <img src={LOGO_URL} className="max-h-52" />
      </div>

      <h2 className="mb-5 text-center text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
        {t('msg_login_form_title')}
      </h2>
      <form
        className="flex flex-col gap-4"
        onSubmit={submitHandler(form, async (values) => {
          await loginUserMutation.mutateAsync(values);
          await login();
          await navigate({ to: '/' });
        })}
      >
        <Controller
          name="username"
          control={form.control}
          rules={validateRules(emailRule(t), requiredRule(t))}
          render={(controllerProps) => (
            <TextInput
              {...mapToInputProps(controllerProps)}
              inputMode="email"
              icon={<EmailIcon width={22} height={22} />}
              label={t('msg_field_email')}
              placeholder={t('msg_field_email')}
            />
          )}
        />
        <Controller
          name="password"
          control={form.control}
          rules={validateRules(requiredRule(t))}
          render={(controllerProps) => (
            <TextInput
              {...mapToInputProps(controllerProps)}
              inputMode="text"
              label={t('msg_field_password')}
              placeholder={t('msg_field_password')}
              icon={<PasswordIcon width={22} height={22} />}
              type="password"
            />
          )}
        />

        {form.formState.errors.root?.message && (
          <div className="mt-2">
            <Error error={form.formState.errors.root.message} />
          </div>
        )}
        <Button type="submit" loading={form.formState.isSubmitting} width="full">
          {t('msg_login_submit_button')}
        </Button>
      </form>

      <div className="mt-4 text-center">
        <p>
          {t('msg_forgot_password_text')}{' '}
          <Link to="/forgot-password" className="text-primary">
            {t('msg_forgot_password_link')}
          </Link>
        </p>
      </div>
    </Box>
  );
};

export const Route = createFileRoute('/_public/login')({
  component: LoginPage,
});
