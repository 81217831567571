import type React from 'react';
import { type ReactNode } from 'react';

import { cn } from '../utils/styles';

const Box = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-wrap items-center">
        <div className="w-full p-4">{children}</div>
      </div>
    </div>
  );
};

export const BoxHeader = ({
  children,
  bordered = true,
  variant = 'h1',
}: {
  children: ReactNode;
  bordered?: boolean;
  variant?: 'h1' | 'h2' | 'h3';
}) => {
  return (
    <h1
      className={cn(
        'text-xl font-normal text-black dark:text-white',
        bordered && 'border-b border-stroke pb-1 dark:border-strokedark',
        variant === 'h1' && 'text-3xl',
        variant === 'h3' && 'text-lg',
      )}
    >
      {children}
    </h1>
  );
};
export const BoxContent = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col gap-3">{children}</div>;
};

export default Box;
