export type UseInputProps = {
  name: string;
  idPrefix?: string;
  readOnly?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
};

export const useInput = ({ idPrefix, name, readOnly, invalid, error, label, required, disabled }: UseInputProps) => {
  const id = (idPrefix || '') + name;

  return {
    inputProps: {
      id,
      name,
      readOnly,
      'aria-invalid': invalid ? ('true' as const) : ('false' as const),
      disabled,
    },
    inputWrapperProps: {
      id,
      error,
      label,
      required,
    },
  };
};
