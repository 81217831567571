import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect } from 'react';

import { useLogoutMutation } from '../services/auth';
import { useCurrentUserQuery } from '../services/user';

export type AuthContext = {
  isAuthenticated: boolean;
  isReady: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = React.createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const userQuery = useCurrentUserQuery();
  const logoutMutation = useLogoutMutation();
  const isReady = !userQuery.isPending;
  const isAuthenticated = !!userQuery.data;

  const logout = useCallback(async () => {
    await logoutMutation.mutateAsync();
    await userQuery.refetch();
  }, [userQuery, logoutMutation]);

  const login = useCallback(async () => {
    await userQuery.refetch();
  }, [userQuery]);

  useEffect(() => {
    if (userQuery.data) {
      Sentry.setUser({ email: userQuery.data.email, id: userQuery.data.id });
    } else {
      Sentry.setUser(null);
    }
  }, [userQuery.data]);

  return <AuthContext.Provider value={{ isAuthenticated, isReady, logout, login }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
