import { captureException, captureMessage, setExtra } from '@sentry/react';
import { isAxiosError } from 'axios';

import EnvConfig from '@/config/env';

export const logError = (value: string) => {
  if (EnvConfig.IS_SENTRY_ENABLED) {
    captureMessage(value, 'error');
  } else {
    // eslint-disable-next-line no-console
    console.error(value);
  }
};

export const logException = (value: unknown) => {
  if (EnvConfig.IS_SENTRY_ENABLED) {
    setExtra('error', isAxiosError(value) ? value.response?.data : value);
    captureException(value);
  } else {
    // eslint-disable-next-line no-console
    console.error(value);
  }
};
