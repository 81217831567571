import type { ReactNode } from 'react';
import React from 'react';

import Error from './error';

type InputWrapperProps = {
  children: ReactNode;
  required?: boolean;
  htmlFor?: string;
  label?: string;
  error?: string;
};

const InputWrapper = ({ children, error, htmlFor, label, required }: InputWrapperProps) => {
  return (
    <div className="flex size-full flex-col justify-center">
      {label && (
        <label className="mb-1 text-sm font-medium text-black dark:text-white" htmlFor={htmlFor}>
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}
      {children}
      {error && (
        <div className="mt-1">
          <Error error={error} />
        </div>
      )}
    </div>
  );
};

export default InputWrapper;
