import React from 'react';

import type { Post } from '../../services/post';

import CenteredModalWrapper from './centered-modal-wrapper';
import type { ModalProps } from './modal';

type PostModalProps = {
  post: Post;
} & ModalProps;

const PostModal = ({ closeModal, post }: PostModalProps) => {
  return (
    <CenteredModalWrapper onClose={closeModal} title={post.title} limitWidth={false}>
      <div
        dangerouslySetInnerHTML={{
          __html: post.content,
        }}
      ></div>
    </CenteredModalWrapper>
  );
};

export default PostModal;
